<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { formatUsername } from '@wastedondestiny/destiny-library'

const router = useRouter()
const route = useRoute()

const patreonToken = ref<string>()
const discordToken = ref<string>()
const bungieToken = ref<string>()

const patreonData = ref<PatreonData>()
const discordData = ref<DiscordData>()
const bungieData = ref<BungieData>()

const saved = ref(false)

const bungieUsername = computed(() => {
  if (!bungieData.value?.Response) return
  let account

  if (bungieData.value.Response.primaryMembershipId) {
    account = bungieData.value.Response.destinyMemberships.find(x => x.membershipId === bungieData.value!.Response.primaryMembershipId)
  } else {
    account = bungieData.value.Response.destinyMemberships.find(x => x.membershipType === x.crossSaveOverride)
  }

  if (!account) return

  return formatUsername(account.bungieGlobalDisplayName, account.bungieGlobalDisplayNameCode, account.displayName)
})

const patreonPledge = computed(() => {
  if (patreonData.value) {
    const campaign = patreonData.value.included?.find(x => x.type === 'campaign' && x.id === import.meta.env.VITE_PATREON_CAMPAIGN)

    if (campaign) {
      const creatorId = campaign.relationships?.creator?.data.id
      const pledge = patreonData.value.included?.find(x => x.type === 'pledge' && x.relationships?.creator?.data.id === creatorId)

      if (pledge && !pledge.attributes.declined_since) {
        const reward = patreonData.value.included?.find(x => x.type === 'reward' && x.id === pledge.relationships?.reward?.data.id)

        if (reward) {
          const formatter = new Intl.NumberFormat('en-CA', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: reward.attributes.patron_currency
          })
          return `${reward.attributes.title} (${formatter.format(parseInt(reward.attributes.amount_cents) / 100)})`
        }
      }
    }

    if (patreonData.value.data.relationships?.campaign?.data.id === import.meta.env.VITE_PATREON_CAMPAIGN) {
      return 'Architect'
    }
  }

  return null
})

const patreonUrl = `https://www.patreon.com/oauth2/authorize/?response_type=code&client_id=${import.meta.env.VITE_PATREON_ID}&redirect_uri=${location.origin + location.pathname}&scope=identity%20identity%5Bemail%5D%20identity.memberships%20campaigns&state=patreon`
const discordUrl = `https://discord.com/api/oauth2/authorize/?response_type=code&client_id=${import.meta.env.VITE_DISCORD_ID}&redirect_uri=${location.origin + location.pathname}&scope=identify&state=discord`
const bungieUrl = `https://www.bungie.net/en/oauth/authorize/?response_type=code&client_id=${import.meta.env.VITE_BUNGIE_ID}&state=bungie`

async function getData(service: 'patreon'|'discord'|'bungie', code: string, access_token: string = '') {
  const url = new URL(`${import.meta.env.VITE_API_URL}/supporters/${service}/`)
  url.search = new URLSearchParams({
    code,
    access_token
  }).toString()
  const response = await fetch(url, {
    method: 'POST'
  })
  const result = await response.json()
  console.log(result)
  return result as JWT
}

function remove(service: 'patreon'|'discord'|'bungie') {
  switch (service) {
    case 'patreon':
      patreonToken.value = undefined
      patreonData.value = undefined
      localStorage.removeItem('patreon')
      break
    case 'discord':
      discordToken.value = undefined
      discordData.value = undefined
      localStorage.removeItem('discord')
      break
    case 'bungie':
      bungieToken.value = undefined
      bungieData.value = undefined
      localStorage.removeItem('bungie')
      break
  }
}

async function confirm() {
  await fetch(`${import.meta.env.VITE_API_URL}/supporters`, {
    method: 'POST',
    body: JSON.stringify({
      patreon: patreonData.value?.access_token,
      discord: discordData.value?.access_token,
      bungie: bungieData.value?.access_token
    })
  })
  saved.value = true
}

watch(bungieToken, async (access_token) => {
  if (access_token && !bungieData.value) {
    const response = await getData('bungie', '', access_token)
    bungieData.value = response as BungieData
  }
})

watch(discordToken, async (access_token) => {
  if (access_token && !discordData.value) {
    const response = await getData('discord', '', access_token)
    discordData.value = response as DiscordData
  }
})

watch(patreonToken, async (access_token) => {
  if (access_token && !patreonData.value) {
    const response = await getData('patreon', '', access_token)
    patreonData.value = response as PatreonData
  }
})

onMounted(async () => {
  if (route.query.code && route.query.state) {
    const platform = route.query.state as 'patreon'|'discord'|'bungie'

    try {
      const response = await getData(platform, route.query.code as string)
      localStorage.setItem(platform, response.access_token)

      switch (platform) {
        case 'patreon':
          patreonData.value = response as PatreonData
          break
        case 'discord':
          discordData.value = response as DiscordData
          break
        case 'bungie':
          bungieData.value = response as BungieData
          break
      }
    } catch (_) {}

    router.replace({ name: route.name?.toString() })
  }

  const _patreonToken = localStorage.getItem('patreon')
  if (_patreonToken) patreonToken.value = _patreonToken

  const _discordToken = localStorage.getItem('discord')
  if (_discordToken) discordToken.value = _discordToken

  const _bungieToken = localStorage.getItem('bungie')
  if (_bungieToken) bungieToken.value = _bungieToken
})
</script>

<template>
  <div class="w-full md:w-5/6 lg:w-4/5 px-2 py-4 text-zinc-200 mx-auto">
    <RouterLink to="/" class="flex items-center text-lg"><img src="../assets/caret.svg" class="h-6 w-6 invert rotate-90" />Go back</RouterLink>
    <h1 id="about" class="text-center text-4xl my-2 r-zinc-200 pb-2">Time Wasted on Destiny Patreon Integration</h1>
    <div class="flex flex-col gap-3">
      <div class="flex flex-col justify-center items-center gap-x-8 text-black">
        <div class="flex flex-col w-72 bg-white shadow-lg shadow-black/50 rounded-2xl px-2 py-3 mb-5 relative">
          <div class="flex items-center justify-between -mt-3 mb-1 w-72 -mx-2 rounded-t-2xl py-1 px-2 bg-zinc-200 h-6">
            <span class="text-xs">PATREON</span>
            <img @click="() => remove('patreon')" alt="Close button" v-if="patreonData?.data" src="../assets/close.svg" class="h-4 w-4 cursor-pointer opacity-75 hover:opacity-100" />
            <span class="text-[0.625rem] text-red-700" v-else>REQUIRED</span>
          </div>
          <template v-if="patreonData?.data">
            <div class="pb-1 mb-1 border-b-2 border-black/25">
              <h2 class="text-xl font-semibold">{{ patreonData.data.attributes['full_name'] }}</h2>
            </div>
            <span>You pledged: {{ patreonPledge }}. Thank you very much for your contribution!</span>
          </template>
          <a class="no-underline flex items-center justify-center -mb-1 mt-1 p-2 bg-black text-white opacity-80 hover:opacity-100 rounded-b-lg rounded-t-sm" :href="patreonUrl" v-else>Sign-in with Patreon</a>
        </div>
      </div>
      
      <div class="flex flex-col sm:flex-row justify-center items-center sm:items-start gap-x-8 text-black" v-if="patreonData?.data">
        <div class="flex flex-col w-72 bg-white shadow-lg shadow-black/50 rounded-2xl px-2 py-3 mb-5 relative">
          <div class="flex items-center justify-between -mt-3 mb-1 w-72 -mx-2 rounded-t-2xl py-1 px-2 bg-zinc-200 h-6">
            <span class="text-xs">DISCORD</span>
            <img @click="() => remove('discord')" alt="Close button" v-if="discordData?.id" src="../assets/close.svg" class="h-4 w-4 cursor-pointer opacity-75 hover:opacity-100" />
            <span class="text-[0.625rem] text-amber-700" v-else>OPTIONAL</span>
          </div>
          <template v-if="discordData?.id">
            <div class="pb-1 mb-1 border-b-2 border-black/25">
              <h2 class="text-xl font-semibold">{{ discordData.global_name || `${discordData.username}#${discordData.discriminator}` }}</h2>
            </div>
            <a class="block" target="_blank" href="https://discord.wastedondestiny.com">Click here to join the Discord server!</a>
            <span>You will get a role corresponding to your Patreon pledge tier.</span>
          </template>
          <a class="no-underline flex items-center justify-center -mb-1 mt-1 p-2 bg-black text-white opacity-80 hover:opacity-100 rounded-b-lg rounded-t-sm" :href="discordUrl" v-else>Sign-in with Discord</a>
        </div>
        <div class="flex flex-col w-72 bg-white shadow-lg shadow-black/50 rounded-2xl px-2 py-3 mb-5 relative">
          <div class="flex items-center justify-between -mt-3 mb-1 w-72 -mx-2 rounded-t-2xl py-1 px-2 bg-zinc-200 h-6">
            <span class="text-xs">BUNGIE</span>
            <img @click="() => remove('bungie')" alt="Close button" v-if="bungieData?.Response" src="../assets/close.svg" class="h-4 w-4 cursor-pointer opacity-75 hover:opacity-100" />
            <span class="text-[0.625rem] text-amber-700" v-else>OPTIONAL</span>
          </div>
          <template v-if="bungieData?.Response">
            <div class="pb-1 mb-1 border-b-2 border-black/25">
              <h2 class="text-xl font-semibold">{{ bungieUsername }}</h2>
            </div>
            <span>Your accounts will show your Patreon pledge tier on Time Wasted on Destiny and crimson.report!</span>
          </template>
          <a class="no-underline flex items-center justify-center -mb-1 mt-1 p-2 bg-black text-white opacity-80 hover:opacity-100 rounded-b-lg rounded-t-sm" :href="bungieUrl" v-else>Sign-in with Bungie</a>
        </div>
      </div>

      <div class="flex flex-col justify-center items-center gap-x-8 text-black mb-3" v-if="patreonData?.data && (discordData?.id || bungieData?.Response)">
        <div class="flex flex-col w-72 bg-white shadow-lg shadow-black/50 rounded-2xl px-2 py-3 mb-5 relative">
          <div class="flex items-center justify-between -mt-3 mb-1 w-72 -mx-2 rounded-t-2xl py-1 px-2 bg-zinc-200 h-6">
            <span class="text-xs">CONFIRM</span>
            <span class="text-[0.625rem] text-red-700">REQUIRED</span>
          </div>
          <span v-if="saved">Thank you very much for contributing!</span>
          <template v-else>
            <span>Make sure the correct accounts are linked before confirming.</span>
            <button @click="confirm" class="no-underline flex items-center justify-center -mb-1 mt-1 p-2 bg-black text-white opacity-80 hover:opacity-100 rounded-b-lg rounded-t-sm">Save my settings</button>
          </template>
        </div>
      </div>
    </div>
  </div>
  <footer class="mb-10 text-center text-zinc-200">
    <span class="block sm:inline">&copy; François (binarmorker) Allard {{ (new Date()).getFullYear() }}</span>
    <span class="hidden sm:inline"> | </span>
    <a target="_blank" href="http://www.patreon.com/wastedondestiny" class="font-bold shadow-black/50 text-shadow-sm block sm:inline opacity-75 hover:opacity-100">Support me on Patreon!</a>
    <span class="hidden sm:inline"> | </span>
    <a target="_blank" href="https://dsc.gg/wastedondestiny" class="block sm:inline opacity-75 hover:opacity-100">Join me on Discord</a>
    <span class="hidden sm:inline"> | </span>
    <RouterLink to="/" class="block sm:inline opacity-75 hover:opacity-100">Back to Time Wasted on Destiny</RouterLink>
  </footer>
</template>

<style lang="postcss">
html {
  scroll-behavior: smooth;
}
</style>