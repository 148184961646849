<script setup lang="ts">
import { Ref, computed, inject } from 'vue'
import {
  type Account,
  type AccountResult,
  type Activity,
  type Character,
  type Parallel,
  type Supporter,
  Collapsible,
  Dropdown,
  PlatformIcon,
  TheTooltip,
  getPerfectRounding,
  formatDate,
  formatNumber,
  formatTime,
  timeSince,
  Season
} from '@wastedondestiny/destiny-library'
import { groupActivities } from '../utils'
import LevelIcon from './LevelIcon.vue'
import LineChart from './LineChart.vue'
import GuardianRank from './GuardianRank.vue'
import titleDefinitions from '../generated/titleDefinitions.json'
import GildedIcon from './GildedIcon.vue'
import FallbackImage from './FallbackImage.vue'

const emit = defineEmits(['toggle', 'remove', 'loadFireteam'])
const props = withDefaults(defineProps<{
  account: Account,
  accounts: Account[],
  rank?: number,
  activities?: Activity[],
  activitiesLoaded?: boolean,
  loading?: boolean,
  isOpen: { detail: boolean, seasons: boolean, activities: boolean, characters: boolean },
  isDemo?: boolean
}>(), {
  loading: false,
  isDemo: false,
  activitiesLoaded: false
})
const supporters = inject<Supporter[]>('supporters')
const parallel = inject<Parallel[]>('parallel')

const selectedClan = inject('selectedClan') as Ref<Clan>
  
const thirtyDaysAgo = computed(() => {
  const now = new Date()
  now.setDate(now.getDate() - 30)
  return now
})
const isLegacyAccount = computed(() => props.account.crossSaveOverride === 99999)
const unloadedFireteamMembers = computed(() => props.account.fireteam.filter(x => !props.accounts.map(y => y.membershipId).includes(x)))
const canLoadFireteam = computed(() => unloadedFireteamMembers.value.length > 0 && !props.loading)
const anyCanLoadFireteam = computed(() => props.accounts.some((x, _, all) => !x.fireteam.every(y => all.map(w => w.membershipId).includes(y))))
const timePlayed = computed(() => formatTime(props.account.characters.filter(x => !isLegacyCharacter(x)).reduce((sum, x) => sum + x.timePlayed!, 0)))
const timeDeleted = computed(() => formatTime(props.account.characters.filter(x => x.deleted && !isLegacyCharacter(x)).reduce((sum, x) => sum + x.timePlayed!, 0)))
const timeActive = computed(() => formatTime(props.account.characters.filter(x => !x.deleted && !isLegacyCharacter(x)).reduce((sum, x) => sum + x.timePlayed!, 0)))
const timeAfk = computed(() => formatTime(props.account.characters.filter(x => !x.deleted && !isLegacyCharacter(x)).reduce((sum, x) => sum + x.timeAfk!, 0)))

const d1TimePlayed = computed(() => props.account.d1TotalTime ? formatTime(props.account.d1TotalTime) : 'Not played')
const d1TimeDeleted = computed(() => props.account.d1DeletedTime ? formatTime(props.account.d1DeletedTime) : '0h')

const legacyTitles = computed(() => titleDefinitions.filter(x => x.isLegacy).map(x => props.account.titles?.find(y => y.id === x.id) ?? { ...x, isNotUnlocked: true }) as {
  id: string,
  name: string,
  gilding?: string,
  isLegacy: boolean,
  gildedCount?: number,
  isNotUnlocked?: boolean
}[])
const activeTitles = computed(() => titleDefinitions.filter(x => !x.isLegacy).map(x => props.account.titles?.find(y => y.id === x.id) ?? { ...x, isNotUnlocked: true }) as {
  id: string,
  name: string,
  gilding?: string,
  isLegacy: boolean,
  gildedCount?: number,
  isNotUnlocked?: boolean
}[])
const characterTitles = computed(() => props.account.characters.reduce((a, x) => {
  const charTitle = props.account.titles?.find(y => +y.id === x.title)
                 || titleDefinitions.find(y => +y.id === x.title)
  a[x.characterId] = charTitle as {
    id: string,
    name: string,
    gilding?: string,
    isLegacy: boolean,
    gildedCount: number
  }
  return a
}, {} as Record<string, {
  id: string,
  name: string,
  gilding?: string,
  isLegacy: boolean,
  gildedCount: number
}>))

const sortedCharacters = computed(() => props.account.characters.sort((a, b) =>
  (b.timePlayed! - (isLegacyCharacter(b) ? 88888888 : 0) - (b.deleted ? 99999999 : 0)) - (a.timePlayed! - (isLegacyCharacter(a) ? 88888888 : 0) - (a.deleted ? 99999999 : 0))
))
const groupedActivitiesPercentages = computed(() => {
  if (!props.activitiesLoaded || !props.activities?.length) return null

  const groupedActivities = groupActivities(props.activities)
  const allTime = groupedActivities.flat().reduce((sum, x) => sum + x.timePlayed, 0)
  const beforeRounding = [
    (groupedActivities[0].map(x => x.timePlayed).reduce((a, x) => a + x, 0) || 0) / allTime * 100,
    (groupedActivities[1].map(x => x.timePlayed).reduce((a, x) => a + x, 0) || 0) / allTime * 100,
    (groupedActivities[2].map(x => x.timePlayed).reduce((a, x) => a + x, 0) || 0) / allTime * 100,
    (groupedActivities[3].map(x => x.timePlayed).reduce((a, x) => a + x, 0) || 0) / allTime * 100,
    (groupedActivities[4].map(x => x.timePlayed).reduce((a, x) => a + x, 0) || 0) / allTime * 100,
    (groupedActivities[5].map(x => x.timePlayed).reduce((a, x) => a + x, 0) || 0) / allTime * 100
  ]
  const percentages = getPerfectRounding(beforeRounding)
  
  return {
    story: `${percentages[0]}%`,
    strikes: `${percentages[1]}%`,
    raids: `${percentages[2]}%`,
    dungeons: `${percentages[3]}%`,
    gambit: `${percentages[4]}%`,
    crucible: `${percentages[5]}%`
  }
})
const lastMonthActivity = computed(() => {
  if (!props.activitiesLoaded) return Array(30).fill(0) as number[]

  const activityTimeByDay = new Map<string, number>()

  for (let i = 0; i < 30; i++) {
    const date = new Date(thirtyDaysAgo.value)
    date.setDate(date.getDate() + i)
    activityTimeByDay.set(date.toISOString().split('T')[0], 0)
  }

  for (const activity of props.activities || []) {
    const activityDate = activity.period.toISOString().split('T')[0]

    if (activityTimeByDay.has(activityDate)) {
      activityTimeByDay.set(activityDate, activityTimeByDay.get(activityDate)! + activity.timePlayed)
    }
  }

  return Array.from(activityTimeByDay.values())
})

const crLink = computed(() => `https://crimson.report/${props.account.membershipId}`)
const lrLink = computed(() => props.account.clan ? `https://loot.report/${props.account.clan.id}` : '')

function formatCharacter(character: Character) {
  if (character.deleted) return 'Deleted'
  const gender = character.gender === 3111576190 ? 'Male' : 'Female'
  const race = character.race === 3887404748 ? 'Human' : character.race === 2803282938 ? 'Awoken' : 'Exo'
  const _class = character.class === 3655393761 ? 'Titan' : character.class === 671679327 ? 'Hunter' : 'Warlock'
  return `${gender} ${race} ${_class}`
}

function isLegacyCharacter(character: Character) {
  return character.characterId.startsWith('legacy')
}

function getEmblemUrl(character: Character) {
  if (typeof character.emblem === 'string') {
    const emblemString = character.emblem as string
    const [emblem, background] = emblemString.split('|')
    return [emblem, background]
  }

  return [`/generated/${character.emblem}.jpg`, null]
}

function getDefaultEmblem(character: Character) {
  if (isLegacyCharacter(character)) {
    return '/default.jpg'
  }

  switch (character.class) {
    case 3655393761: return '/generated/1907674139.jpg'
    case 671679327: return '/generated/1907674138.jpg'
    case 2271682572: return '/generated/1907674137.jpg'
    default: return '/default_large.jpg'
  }
}

function toggle(tag: 'detail'|'seasons'|'activities'|'characters') {
  emit('toggle', tag)
}

function remove(account: AccountResult) {
  emit('remove', account)
}

function loadFireteam() {
  emit('loadFireteam', unloadedFireteamMembers.value)
}

function selectClan() {
  selectedClan.value = props.account.clan!
  scrollTo({
    top: document.getElementById('leaderboard')?.offsetTop,
    behavior: 'smooth'
  })
}

function getSeasonMaxRank(currentSeason: Season) {
  if (currentSeason.number !== props.account.seasons?.sort((a, b) => a.number - b.number)?.at(-1)?.number) {
    return '/200'
  }

  if (currentSeason.startDate) {
    const currentWeek = Math.floor((Date.now() - new Date(currentSeason.startDate).getTime()) / 604_800_000) - 0 // Milliseconds in a week
    const act = Math.floor(currentWeek / 6) + 2
    const level = 50 + (act * 50)
    return `/${level}`
  }

  return ''
}
</script>

<template>
  <div class="flex flex-col w-72 bg-white shadow-lg shadow-black/50 rounded-2xl px-2 py-3 mb-5 relative">
    <div class="flex items-center justify-between -mt-3 mb-1 w-72 -mx-2 rounded-t-2xl py-1 px-2 bg-zinc-200" v-if="isDemo">
      <span />
      <img src="../assets/close.svg" alt="Close button" class="h-4 w-4 opacity-75" />
    </div>
    <div class="flex items-center justify-between -mt-3 mb-1 w-72 -mx-2 rounded-t-2xl py-1 px-2 bg-zinc-200" v-else>
      <PlatformIcon
        :account="account"
        :supporting="supporters?.find(x => x.linkedMembershipIds?.includes(account.membershipId))"
        :parallel="parallel?.find(x => x.members.find(y => y.membershipId === account.membershipId))"
      />
      <div class="flex gap-1">
        <template v-if="rank">
          <TheTooltip :no-interact="isDemo" v-if="rank < 200">
            <span class="uppercase text-xs h-4 leading-4 block">TOP {{ rank }}%</span>
            <template #icon>
              <img src="../assets/seasonal.svg" alt="Percentile" class="h-6 w-6" />
            </template>
            <template #content>This member is in the top {{ rank }}% of time played among ranked players</template>
          </TheTooltip>
          <TheTooltip :no-interact="isDemo" v-else>
            <span class="uppercase text-xs h-4 leading-4 block">RANK #{{ rank - 199 }}</span>
            <template #icon>
              <img src="../assets/seasonal.svg" alt="Percentile" class="h-6 w-6" />
            </template>
            <template #content>This member is in rank {{ rank - 199 }} of time played among ranked players</template>
          </TheTooltip>
        </template>
        <template v-else-if="!isLegacyAccount">
          <div class="text-xl leading-7 font-serif font-bold flex gap-1 justify-center items-center h-3.5">
            <span class="transition-opacity bullet-1">&bullet;</span>
            <span class="transition-opacity bullet-2">&bullet;</span>
            <span class="transition-opacity bullet-3">&bullet;</span>
          </div>
        </template>
        <template v-else>
          <small class="text-xs h4 leading-4 text-zinc-500">DESTINY 1</small>
        </template>
        <img @click="() => remove(account)" alt="Close button" src="../assets/close.svg" class="h-4 w-4 cursor-pointer opacity-75 hover:opacity-100" />
      </div>
    </div>
    <div class="pb-1 mb-1 border-b-2 border-black/25">
      <h2 class="text-xl font-semibold">{{ account.displayName }}</h2>
      <Dropdown v-if="!isDemo">
        <a v-if="!isLegacyAccount" class="no-underline opacity-75 hover:opacity-100 p-2" target="_blank" :href="crLink">
          <img class="h-4 w-4 inline-block" src="../assets/crimsonreport.svg" /> Check owned content on crimson.report
        </a>
        <a v-if="lrLink" class="no-underline opacity-75 hover:opacity-100 p-2 border-t-2 border-zinc-500" target="_blank" :href="lrLink">
          <img class="h-4 w-4 inline-block" src="../assets/lootreport.svg" /> Check clan exotics on loot.report
        </a>
        <a class="no-underline opacity-75 hover:opacity-100 p-2 border-t-2 border-zinc-500" target="_blank" href="https://www.bungie.net/7/en/User/Account/Privacy">
          <img class="h-4 w-4 inline-block" src="../assets/close.svg" /> Change my privacy settings
        </a>
      </Dropdown>
      <span v-if="canLoadFireteam" class="text-xs text-black flex items-center cursor-pointer opacity-75 hover:opacity-100" @click="loadFireteam">
        <img src="../assets/group.svg" class="h-5 w-5" />
        <span class="pl-2">Add fireteam ({{ unloadedFireteamMembers.length }} more guardian{{ unloadedFireteamMembers.length > 1 ? 's' : '' }})</span>
      </span>
      <div v-else-if="!loading && anyCanLoadFireteam" class="h-5" />
    </div>
    <template v-if="!isLegacyAccount">
      <div class="flex justify-between">
        <div class="flex flex-col items-start">
          <span class="text-lg font-semibold tabular-nums">{{ timePlayed }}</span>
          <small class="text-xs">ALL CHARACTERS</small>
        </div>
        <div class="flex flex-col items-end text-red-800">
          <span class="text-lg font-semibold tabular-nums">{{ timeDeleted }}</span>
          <small class="text-xs">ONLY DELETED</small>
        </div>
      </div>
      <div class="flex justify-between h-12" v-if="account?.guardianRank">
        <div class="flex flex-col items-start">
          <span class="flex gap-1 items-center">
            <GuardianRank :value="account!.guardianRank!.current" :is-obscured="(account?.guardianRank?.previous ?? 0) > (account?.guardianRank?.current ?? 0)" />
            <small class="text-xs flex items-center" v-if="(account?.guardianRank?.previous ?? 0) > (account?.guardianRank?.current ?? 0)">(Shown: <GuardianRank :value="account!.guardianRank!.previous" />)</small>
          </span>
          <small class="text-xs">GUARDIAN RANK</small>
        </div>
        <div class="flex flex-col items-end">
          <span class="flex gap-1">
            <GuardianRank :value="account!.guardianRank!.highest" />
          </span>
          <small class="text-xs">HIGHEST</small>
        </div>
      </div>
      <div class="h-12" v-else />
      <div class="flex flex-col h-10">
        <button @click="selectClan" class="leading-6 text-left opacity-75 hover:opacity-100" v-if="account?.clan && !isDemo">{{ account.clan.name }}</button>
        <span class="leading-6" :class="{ 'italic text-zinc-400': !account?.clan }" v-else>{{ account?.clan?.name || 'No clan affiliation' }}</span>
        <small class="text-xs">CLAN</small>
      </div>
    </template>
    <template v-else>
      <div class="flex justify-between">
        <div class="flex flex-col items-start">
          <span class="text-lg font-semibold tabular-nums">{{ d1TimePlayed }}</span>
          <small class="text-xs">DESTINY 1</small>
        </div>
        <div class="flex flex-col items-end text-red-800">
          <span class="text-lg font-semibold tabular-nums">{{ d1TimeDeleted }}</span>
          <small class="text-xs">D1 DELETED</small>
        </div>
      </div>
      <div class="text-lg text-zinc-300 p-3 text-center leading-7 flex gap-2 justify-center items-center h-[5.5rem]">
        This is a Destiny 1 only account
      </div>
    </template>
    <div class="flex flex-col">
      <Collapsible name="DETAIL" :open="isOpen['detail']" @toggle="() => toggle('detail')" :no-interact="isDemo">
        <template v-if="!isLegacyAccount">
          <div class="flex justify-between">
            <div class="flex flex-col items-start">
              <span class="text-lg tabular-nums">{{ timeActive }}</span>
              <small class="text-xs">ONLY ACTIVE</small>
            </div>
            <div class="flex flex-col items-end" :class="{ 'opacity-50': account.private }">
              <span class="text-lg tabular-nums">{{ formatNumber(account.activeTriumph!) }}</span>
              <small class="text-xs">ACTIVE TRIUMPH</small>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="flex flex-col items-start">
              <span class="text-lg tabular-nums">{{ timeAfk }}</span>
              <small class="text-xs">TIME AFK (EXCLUDED)</small>
            </div>
            <div class="flex flex-col items-end" :class="{ 'opacity-50': account.private }">
              <span class="text-lg tabular-nums">{{ formatNumber(account.totalTriumph!) }}</span>
              <small class="text-xs">LIFETIME TRIUMPH</small>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="flex flex-col items-start" v-if="account.lastActivity">
              <span class="text-lg h-7 w-full overflow-hidden text-ellipsis">{{ account.lastActivity }}</span>
              <small class="text-xs">LAST ACTIVITY</small>
            </div>
            <div class="flex flex-col items-start" v-else-if="account.dateLastPlayed">
              <span class="text-lg leading-[14px] flex items-center h-7">{{ timeSince(account.dateLastPlayed) }}</span>
              <small class="text-xs">LAST PLAYED</small>
            </div>
            <TheTooltip :no-interact="isDemo">
              <div class="flex flex-col items-end" :class="{ 'opacity-50': account.private }">
                <span class="text-lg tabular-nums">{{ account.titles?.length ?? 0 }}</span>
                <small class="text-xs">TITLES</small>
              </div>
              <template #content>
                <h4>Legacy titles</h4>
                <div class="grid grid-cols-2 md:grid-cols-3 gap-x-2 border-zinc-400 border-t pt-1">
                  <span
                    v-for="title in legacyTitles"
                    class="flex gap-1 text-xs"
                    :class="{ 'text-zinc-900': !title.isNotUnlocked, 'text-zinc-400': title.isNotUnlocked }"
                  >
                    {{ title.name }}
                    <i v-if="title.gilding && title.gildedCount" class="not-italic flex items-center gap-0.5">
                      <GildedIcon class="h-3 w-2 fill-zinc-900" />
                      <small v-if="title.gildedCount > 1" class="text-[10px] leading-3">{{ title.gildedCount }}</small>
                    </i>
                  </span>
                </div>
                <h4 class="mt-2">Active titles</h4>
                <div class="grid grid-cols-2 md:grid-cols-3 gap-x-2 border-zinc-400 border-t pt-1">
                  <span
                    v-for="title in activeTitles"
                    class="flex gap-1 text-xs"
                    :class="{ 'text-zinc-900': !title.isNotUnlocked, 'text-zinc-400': title.isNotUnlocked }"
                  >
                    {{ title.name }}
                    <i v-if="title.gilding && title.gildedCount" class="not-italic flex items-center gap-0.5">
                      <GildedIcon class="h-3 w-2 fill-zinc-900" />
                      <small v-if="title.gildedCount > 1" class="text-[10px] leading-3">{{ title.gildedCount }}</small>
                    </i>
                  </span>
                </div>
              </template>
            </TheTooltip>
          </div>
        </template>
        <template v-else>
          <div class="text-lg text-zinc-300 p-5 text-center leading-7 flex gap-2 justify-center items-center h-[8.75rem]">
            This is a Destiny 1 account. Only time played and deleted are available
          </div>
        </template>
        <div class="flex justify-between opacity-50">
          <div class="flex flex-col items-start">
            <span class="text-lg font-semibold tabular-nums">{{ d1TimePlayed }}</span>
            <small class="text-xs">DESTINY 1</small>
          </div>
          <div class="flex flex-col items-end text-red-800">
            <span class="text-lg font-semibold tabular-nums">{{ d1TimeDeleted }}</span>
            <small class="text-xs">D1 DELETED</small>
          </div>
        </div>
      </Collapsible>
      <Collapsible name="SEASONS" :open="isOpen['seasons']" @toggle="() => toggle('seasons')" :no-interact="isDemo">
        <template v-if="!account.private && !isLegacyAccount">
          <div class="flex justify-between items-center border-b-zinc-200 border-b -mb-1">
            <small class="text-sm"><span class="w-5 inline-block text-zinc-500 tabular-nums">1-7</span>&bullet; Legacy ranks</small>
            <span class="font-semibold tabular-nums">{{ account.beforeSeasons?.seasonPrestige || '-' }}</span>
          </div>
          <div class="grid grid-cols-2 gap-x-4 -mb-1">
            <div class="flex justify-between items-center border-b-zinc-200" :class="{ 'border-b': i < 24 }" v-for="(season, i) in account.seasons?.filter(x => x.number < 24)">
              <small class="text-sm"><span class="w-5 inline-block text-zinc-500 tabular-nums">{{ season.number }}</span>&bullet; {{ season.season.replace(/(Season of (?:the )?|Episode: )/, '') }}</small>
              <span class="font-semibold tabular-nums">{{ season.seasonRank }}</span>
            </div>
          </div>
          <div class="flex justify-between items-center border-b-zinc-200 border-b -mb-1" v-for="season in account.seasons?.filter(x => x.number >= 24)">
            <small class="text-sm"><span class="w-5 inline-block text-zinc-500 tabular-nums">{{ season.number }}</span>&bullet; Episode: {{ season.season.replace(/(Season of (?:the )?|Episode: )/, '') }}</small>
            <span class="font-semibold tabular-nums">{{ season.seasonRank }} <sub class="bottom-0">{{ getSeasonMaxRank(season) }}</sub></span>
          </div>
        </template>
        <template v-else-if="account.private">
          <div class="text-lg text-zinc-300 p-10 text-center leading-7 flex gap-2 justify-center items-center h-60">
            Check your privacy settings on bungie.net
          </div>
        </template>
        <template v-else>
          <div class="text-lg text-zinc-300 p-5 text-center leading-7 flex gap-2 justify-center items-center h-60">
            This is a Destiny 1 account. Only time played and deleted are available
          </div>
        </template>
      </Collapsible>
      <Collapsible name="ACTIVITIES" :open="isOpen['activities']" @toggle="() => toggle('activities')" :no-interact="isDemo">
        <template v-if="activitiesLoaded && !account.private && !isLegacyAccount">
          <div class="h-2 w-full flex mb-1">
            <div class="h-full bg-yellow-400" :style="{ width: groupedActivitiesPercentages?.story || '100%' }">&nbsp;</div>
            <div class="h-full bg-sky-500 fill-diag" :style="{ width: groupedActivitiesPercentages?.strikes || '0%' }">&nbsp;</div>
            <div class="h-full bg-purple-800 fill-bars" :style="{ width: groupedActivitiesPercentages?.raids || '0%' }">&nbsp;</div>
            <div class="h-full bg-fuchsia-700 fill-diag2" :style="{ width: groupedActivitiesPercentages?.dungeons || '0%' }">&nbsp;</div>
            <div class="h-full bg-emerald-500 fill-squares" :style="{ width: groupedActivitiesPercentages?.gambit || '0%' }">&nbsp;</div>
            <div class="h-full bg-red-500 fill-rhomb" :style="{ width: groupedActivitiesPercentages?.crucible || '0%' }">&nbsp;</div>
          </div>
          <div class="h-8 w-full flex justify-around items-end">
            <div class="flex flex-col justify-around items-center w-1/3">
              <small class="text-xs text-center -mb-1">Story</small>
              <div class="flex gap-2 items-center">
                <div class="h-2 w-4 bg-yellow-400">&nbsp;</div>
                <span class="tabular-nums">{{ groupedActivitiesPercentages?.story.replace(/\.\d+/, '') || '100%' }}</span>
              </div>
            </div>
            <div class="flex flex-col justify-around items-center w-1/3">
              <small class="text-xs text-center -mb-1">Strikes</small>
              <div class="flex gap-2 items-center">
                <div class="h-2 w-4 bg-sky-500 fill-diag">&nbsp;</div>
                <span class="tabular-nums">{{ groupedActivitiesPercentages?.strikes.replace(/\.\d+/, '') || '0%' }}</span>
              </div>
            </div>
            <div class="flex flex-col justify-around items-center w-1/3">
              <small class="text-xs text-center -mb-1">Raids</small>
              <div class="flex gap-2 items-center">
                <div class="h-2 w-4 bg-purple-800 fill-bars">&nbsp;</div>
                <span class="tabular-nums">{{ groupedActivitiesPercentages?.raids.replace(/\.\d+/, '') || '0%' }}</span>
              </div>
            </div>
          </div>
          <div class="h-8 w-full flex justify-around items-end">
            <div class="flex flex-col justify-around items-center w-1/3">
              <small class="text-xs text-center -mb-1">Dungeons</small>
              <div class="flex gap-2 items-center">
                <div class="h-2 w-4 bg-fuchsia-700 fill-diag2">&nbsp;</div>
                <span class="tabular-nums">{{ groupedActivitiesPercentages?.dungeons.replace(/\.\d+/, '') || '0%' }}</span>
              </div>
            </div>
            <div class="flex flex-col justify-around items-center w-1/3">
              <small class="text-xs text-center -mb-1">Gambit</small>
              <div class="flex gap-2 items-center">
                <div class="h-2 w-4 bg-emerald-500 fill-squares">&nbsp;</div>
                <span class="tabular-nums">{{ groupedActivitiesPercentages?.gambit.replace(/\.\d+/, '') || '0%' }}</span>
              </div>
            </div>
            <div class="flex flex-col justify-around items-center w-1/3">
              <small class="text-xs text-center -mb-1">Crucible</small>
              <div class="flex gap-2 items-center">
                <div class="h-2 w-4 bg-red-500 fill-rhomb">&nbsp;</div>
                <span class="tabular-nums">{{ groupedActivitiesPercentages?.crucible.replace(/\.\d+/, '') || '0%' }}</span>
              </div>
            </div>
          </div>
          <div class="h-[4.5rem] w-full relative">
            <small class="text-xs absolute right-0 tabular-nums">{{ formatTime(lastMonthActivity?.reduce((acc, x) => acc > x ? acc : x) || 0) }}</small>
            <hr class="border-blue-500/50" />
            <LineChart v-if="lastMonthActivity" :points="lastMonthActivity" :width="288" :height="72" class="h-[4.5rem] w-72 -m-2" />
            <div class="text-xs h-4 flex justify-between">
              <span>{{ formatDate(thirtyDaysAgo) }}</span>
              <span>Today</span>
            </div>
          </div>
        </template>
        <template v-else-if="!account.private && !isLegacyAccount">
          <div class="text-3xl leading-7 font-serif font-bold flex gap-2 justify-center items-center h-40">
            <span class="transition-opacity bullet-1">&bullet;</span>
            <span class="transition-opacity bullet-2">&bullet;</span>
            <span class="transition-opacity bullet-3">&bullet;</span>
          </div>
        </template>
        <template v-else-if="!isLegacyAccount">
          <div class="text-lg text-zinc-300 p-10 text-center leading-7 flex gap-2 justify-center items-center h-40">
            Check your privacy settings on bungie.net
          </div>
        </template>
        <template v-else>
          <div class="text-lg text-zinc-300 p-5 text-center leading-7 flex gap-2 justify-center items-center h-40">
            This is a Destiny 1 account. Only time played and deleted are available
          </div>
        </template>
      </Collapsible>
      <Collapsible name="CHARACTERS" :open="isOpen['characters']" @toggle="() => toggle('characters')" :no-interact="isDemo">
        <div class="flex flex-col w-full overflow-hidden relative text-shadow-sm" :style="{ 'background-image': isLegacyCharacter(character) && !character.deleted ? `url(${getEmblemUrl(character)[1]}` : '' }" v-for="character in sortedCharacters">
          <FallbackImage v-if="!character.deleted" :src="getEmblemUrl(character)[0]!" class="bg-black w-full h-[55px]" :class="{ '!w-[55px]': isLegacyCharacter(character) && !character.deleted }" :fallback-src="getDefaultEmblem(character)" />
          <div v-else class="bg-black w-full h-full">
            <img class="w-[55px] h-[55px] p-1 -ml-1 invert" src="../assets/raid.svg" />
          </div>
          <div class="absolute inset-0 bg-gradient-to-r from-transparent via-black/50 to-black/50"></div>
          <div class="absolute left-[55px] top-0 bottom-0 text-white flex flex-col justify-between py-1 h-[55px]">
            <small class="text-sm">{{ formatCharacter(character) }}</small>
            <span class="text-lg tabular-nums">{{ formatTime(character.timePlayed!) }}</span>
          </div>
          <div class="absolute right-1 -top-0.5 flex flex-col justify-between items-end p-1 h-[55px]">
            <span v-if="!character.deleted" class="text-lg text-yellow-200 flex items-center gap-[2px]">
              <LevelIcon class="h-3 w-3 fill-yellow-200" />
              {{ character.level }}
            </span>
            <span v-if="isLegacyCharacter(character)" class="text-xs text-zinc-200">DESTINY 1</span>
            <span v-else-if="character.title && characterTitles[character.characterId]" class="text-xs text-zinc-200 flex gap-1">
              {{ characterTitles[character.characterId].name }}
              <i v-if="characterTitles[character.characterId].gilding && characterTitles[character.characterId].gildedCount" class="not-italic flex items-center gap-0.5">
                <GildedIcon class="h-3 w-2 fill-zinc-200" />
                <small v-if="characterTitles[character.characterId].gildedCount > 1" class="text-[10px] leading-3">{{ characterTitles[character.characterId].gildedCount }}</small>
              </i>
            </span>
            <span v-else class="text-xs">&nbsp;</span>
          </div>
        </div>
      </Collapsible>
    </div>
    <template v-if="isDemo">
      <TheTooltip class="absolute" style="top: 26px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 32px; width: 284px;" />
        <template #content>
          <p class="w-52">This is the guardian's display name. The #1234 numbers are the determinator that identifies all the unique Example players out there. Yours can be found in-game in the character selection screen.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 158px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 24px; width: 78px;" />
        <template #content>
          <p class="w-52">If you are in a clan, you may click on this to load it on the leaderboard and compare your time played and other stats against all the other members of your clan.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 202px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 24px; width: 284px;" />
        <template #content>
          <p class="w-52">This is a content category. Clicking it expands or collapses its content. This one shows additional detail about your playtime.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 300px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 24px; width: 128px;" />
        <template #content>
          <p class="w-52">Your time AFK is calculated from your active characters only, and some is lost when you delete a character. For this reason, it is not included in the total or the leaderboard.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 420px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 24px; width: 284px;" />
        <template #content>
          <p class="w-52">Here you may see all the seasons you have played and the season rank you reached in all of them. A dash means you did not play that season, or merely opened the game and did not gain enough XP. Legacy ranks are the number of Eververse Engrams you obtained during years 1 and 2.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 520px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 24px; width: 284px;" />
        <template #content>
          <p class="w-52">This section shows your activity breakdown for all your Destiny 2 career. The graph below indicates your time played each day for the past 30 days.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 710px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 24px; width: 284px;" />
        <template #content>
          <p class="w-52">Every character you currently have or have deleted is shown below. The emblem and level is only available for active characters.</p>
        </template>
      </TheTooltip>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.fill-diag {
  background-color: #0ea5e9;
  background-image: repeating-linear-gradient(45deg, #7dd3fc 0, #7dd3fc 2px, #0ea5e9 0, #0ea5e9 50%);
  background-size: 6px 6px;
}

.fill-diag2 {
  background-color: #a21caf;
  background-image: repeating-linear-gradient(-45deg, #ed7dfc 0, #ed7dfc 2px, #a21caf 0, #a21caf 50%);
  background-size: 6px 6px;
}

.fill-bars {
  background-color: #6b21a8;
  opacity: 1;
  background-image: linear-gradient(to right, #9333ea, #9333ea 2px, #6b21a8 2px, #6b21a8 );
  background-size: 4px 100%;
}

.fill-squares {
  background-color: #10b981;
  background-image:  repeating-linear-gradient(45deg, #6ee7b7 25%, transparent 25%, transparent 75%, #6ee7b7 75%, #6ee7b7), repeating-linear-gradient(45deg, #6ee7b7 25%, #10b981 25%, #10b981 75%, #6ee7b7 75%, #6ee7b7);
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
}

.fill-rhomb {
  background-color: #ef4444;
  background-image:  linear-gradient(135deg, #fca5a5 25%, transparent 25%), linear-gradient(225deg, #fca5a5 25%, transparent 25%), linear-gradient(45deg, #fca5a5 25%, transparent 25%), linear-gradient(315deg, #fca5a5 25%, #ef4444 25%);
  background-position:  8px 0, 8px 0, 0 0, 0 0;
  background-size: 8px 8px;
  background-repeat: repeat;
}
</style>
