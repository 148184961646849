<script setup lang="ts">
import { onMounted, provide, ref } from 'vue'
import AboutPage from './AboutPage.vue'
import Dialog from './Dialog.vue'
import LeaderboardPage from './LeaderboardPage.vue'
import SearchPage from './SearchPage.vue'
import { type Dialog as DialogType, type Parallel, type Supporter } from '@wastedondestiny/destiny-library'

const supporters = ref<Supporter[]>([])
const parallel = ref<Parallel[]>([])
const selectedClan = ref<Clan>()
const dialogs = ref([] as DialogType[])

function removeDialog() {
  const dialog = dialogs.value.pop()

  if (dialog) {
    const clearedDialogs = JSON.parse(localStorage.getItem('read-dialogs') || '[]') as number[]
    clearedDialogs.push(dialog.id)
    localStorage.setItem('read-dialogs', JSON.stringify(clearedDialogs))
  }
}

async function loadSupporters() {
  const supportersRequest = await fetch(`${import.meta.env.VITE_API_URL}/supporters`)
  const supportersResponse = await supportersRequest.json()
  supporters.value = supportersResponse
}

async function loadParallel() {
  const parallelRequest = await fetch('https://b.vlsp.network/Public/ParallelPrograms')
  const parallelResponse = await parallelRequest.json()
  parallel.value = parallelResponse.Response
}

async function loadDialogs() {
  const dialogsRequest = await fetch(`${import.meta.env.VITE_API_URL}/news/?include=wastedondestiny.com`)
  const dialogsResponse = await dialogsRequest.json() as DialogType[]
  const clearedDialogs = JSON.parse(localStorage.getItem('read-dialogs') || '[]') as number[]
  dialogs.value = dialogsResponse.filter(x => !clearedDialogs.includes(x.id))
}

onMounted(() => Promise.all([loadSupporters(), loadParallel(), loadDialogs()]))

provide('supporters', supporters)
provide('parallel', parallel)
provide('selectedClan', selectedClan)
</script>

<template>
  <div :class="{ 'h-screen overflow-hidden pointer-events-none': dialogs.length }">
    <SearchPage />
    <LeaderboardPage />
    <AboutPage />
  </div>
  <div class="fixed inset-0" v-if="dialogs.length" style="z-index: 9999;">
    <Dialog :dialog="dialogs.at(-1)!" @close="removeDialog" />
  </div>
</template>
