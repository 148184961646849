import { type Activity } from '@wastedondestiny/destiny-library'
import { DestinyActivityModeType } from 'quria'

export const activityModes = {
  story: [
    DestinyActivityModeType.Story,
    // DestinyActivityModeType.Patrol,
    DestinyActivityModeType.HeroicAdventure,
    DestinyActivityModeType.BlackArmoryRun,
    DestinyActivityModeType.Menagerie,
    DestinyActivityModeType.VexOffensive,
    DestinyActivityModeType.Sundial,
    DestinyActivityModeType.Dares,
    DestinyActivityModeType.Offensive,
    DestinyActivityModeType.LostSector,
    DestinyActivityModeType.NightmareHunt,
  ],
  strikes: [
    DestinyActivityModeType.Strike,
    DestinyActivityModeType.Nightfall,
    DestinyActivityModeType.HeroicNightfall,
    DestinyActivityModeType.AllStrikes,
    DestinyActivityModeType.ScoredNightfall,
    DestinyActivityModeType.ScoredHeroicNightfall,
  ],
  raids: [
    DestinyActivityModeType.Raid,
  ],
  dungeons: [
    DestinyActivityModeType.Dungeon,
  ],
  gambit: [
    DestinyActivityModeType.Gambit,
    DestinyActivityModeType.AllPvECompetitive,
    DestinyActivityModeType.GambitPrime,
    DestinyActivityModeType.Reckoning,
    -1
  ],
  crucible: [
    DestinyActivityModeType.AllPvP,
    DestinyActivityModeType.Control,
    DestinyActivityModeType.Clash,
    DestinyActivityModeType.CrimsonDoubles,
    DestinyActivityModeType.IronBanner,
    DestinyActivityModeType.AllMayhem,
    DestinyActivityModeType.Supremacy,
    DestinyActivityModeType.PrivateMatchesAll,
    DestinyActivityModeType.Survival,
    DestinyActivityModeType.Countdown,
    DestinyActivityModeType.TrialsOfTheNine,
    DestinyActivityModeType.TrialsCountdown,
    DestinyActivityModeType.TrialsSurvival,
    DestinyActivityModeType.IronBannerControl,
    DestinyActivityModeType.IronBannerClash,
    DestinyActivityModeType.IronBannerSupremacy,
    DestinyActivityModeType.Rumble,
    DestinyActivityModeType.AllDoubles,
    DestinyActivityModeType.Doubles,
    DestinyActivityModeType.PrivateMatchesClash,
    DestinyActivityModeType.PrivateMatchesControl,
    DestinyActivityModeType.PrivateMatchesSupremacy,
    DestinyActivityModeType.PrivateMatchesCountdown,
    DestinyActivityModeType.PrivateMatchesSurvival,
    DestinyActivityModeType.PrivateMatchesMayhem,
    DestinyActivityModeType.PrivateMatchesRumble,
    DestinyActivityModeType.Showdown,
    DestinyActivityModeType.Lockdown,
    DestinyActivityModeType.Scorched,
    DestinyActivityModeType.ScorchedTeam,
    DestinyActivityModeType.Breakthrough,
    DestinyActivityModeType.Salvage,
    DestinyActivityModeType.IronBannerSalvage,
    DestinyActivityModeType.PvPCompetitive,
    DestinyActivityModeType.PvPQuickplay,
    DestinyActivityModeType.ClashQuickplay,
    DestinyActivityModeType.ClashCompetitive,
    DestinyActivityModeType.ControlQuickplay,
    DestinyActivityModeType.ControlCompetitive,
    DestinyActivityModeType.Elimination,
    DestinyActivityModeType.Momentum,
    DestinyActivityModeType.TrialsOfOsiris,
    DestinyActivityModeType.Rift,
    DestinyActivityModeType.ZoneControl,
    DestinyActivityModeType.IronBannerRift,
    DestinyActivityModeType.IronBannerZoneControl,
  ]
}

export const allActivityModes = activityModes.story.concat(activityModes.strikes, activityModes.raids, activityModes.dungeons, activityModes.gambit, activityModes.crucible)

export function groupActivities(activities: Activity[]) {
  const groupedActivities: Activity[][] = [[], [], [], [], [], [], []]

  for (const activity of activities) {
    if (activity.modes.some(x => activityModes.crucible.includes(x))) groupedActivities[5].push(activity)
    else if (activity.modes.some(x => activityModes.gambit.includes(x))) groupedActivities[4].push(activity)
    else if (activity.modes.some(x => activityModes.dungeons.includes(x))) groupedActivities[3].push(activity)
    else if (activity.modes.some(x => activityModes.raids.includes(x))) groupedActivities[2].push(activity)
    else if (activity.modes.some(x => activityModes.strikes.includes(x))) groupedActivities[1].push(activity)
    else if (activity.modes.some(x => activityModes.story.includes(x))) groupedActivities[0].push(activity)
    else groupedActivities[6].push(activity)
  }

  return groupedActivities
}

export function parseHtmlEntities(str: string) {
  return str.replace(/&#([0-9]{1,4});/gi, (_, numStr: string) => {
    const num = parseInt(numStr, 10)
    return String.fromCharCode(num)
  })
}
