<script setup lang="ts">
import { type Dialog } from '@wastedondestiny/destiny-library'
import { computed } from 'vue'

const props = defineProps<{ dialog: Dialog }>()
const emit = defineEmits(['close'])

const imageSrc = computed(() => props.dialog.image?.startsWith('/') ? `${import.meta.env.VITE_API_URL}${props.dialog.image}` : props.dialog.image)

function close() {
  emit('close')
}

function goTo(link: string) {
  window.open(link, '_blank')
}
</script>

<template>
  <div class="w-full h-[100dvh] py-24 flex flex-col justify-center bg-black/10 backdrop-blur-lg font-mono" :key="dialog.id" @click.self="close">
    <div class="w-full h-2 flex justify-end text-lg bg-zinc-400/90">
      &nbsp;
    </div>
    <div class="w-full max-h-[40rem] h-full flex justify-center">
      <img class="object-cover w-[40%] h-full hidden md:block" :src="imageSrc" />
      <div class="w-full md:w-[60%] h-full bg-black/80 text-white overflow-y-auto">
        <h1 class="block w-full text-3xl px-3 py-6 bg-white/20 font-semibold">{{ dialog.title }}</h1>
        <div class="max-w-[60rem] text-xl px-3 py-4 dialog-md" v-html="dialog.text" />
      </div>
    </div>
    <div class="w-full md:pl-[40%] flex flex-wrap justify-start text-lg bg-black/90 text-white font-semibold">
      <button v-for="(link, name) in dialog.callsToAction" @click="() => goTo(link)" class="px-4 h-12 hover:bg-white/25 uppercase">{{ name }}</button>
      <button class="mx-2 px-4 h-12 hover:bg-white/25 uppercase" @click="close">Close</button>
    </div>
  </div>
</template>
