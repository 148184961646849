import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './routes'
import Tracker from '@openreplay/tracker'
import * as Sentry from '@sentry/vue'

try {
  const tracker = new Tracker({
    projectKey: 'fNvKiMWYWCYaDn5x9nUU',
    ingestPoint: 'https://mon.binar.ca/ingest',
  })
  tracker?.start()
} catch (_) {
  // noop
}

window.googleAdsClient = import.meta.env.VITE_GOOGLE_ADS_CLIENT
window.googleAdsSlot = import.meta.env.VITE_GOOGLE_ADS_SLOT

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://5e7932b7cc9d424ab24d05d0967cf33f@log.binar.ca/4',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app.use(router)
app.mount('#app')

console.log('%cTime Wasted on Destiny loaded!', 'color:teal;-webkit-text-stroke:1px black;font-size:36px;')
setTimeout(() => console.log('You wouldn\'t paste code a stranger online gave you into this console, would you?'), 1000)
