<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 873 169" style="enable-background:new 0 0 873 169;" xml:space="preserve">
    <g class="-translate-x-[5.325rem] md:translate-x-0">
      <path style="fill:#FFFFFF;" d="M171.63,54.172h24.268v5.148h-9.074v25.028h-6.12V59.32h-9.074V54.172z"/>
      <path style="fill:#FFFFFF;" d="M207.671,54.172h6.12v30.176h-6.12V54.172z"/>
      <path style="fill:#FFFFFF;" d="M227.127,54.172h8.61L240,69.323c1.054,3.8,1.9,8.146,1.9,8.146h0.084c0,0,0.8-4.347,1.858-8.146
        l4.262-15.151h8.737v30.176h-5.7v-17.3c0-2.447,0.254-6.246,0.254-6.246h-0.085c0,0-0.674,3.545-1.307,5.74l-5.192,17.81h-5.867
        l-5.064-17.81c-0.633-2.2-1.309-5.74-1.309-5.74h-0.084c0,0,0.253,3.8,0.253,6.246v17.3h-5.613L227.127,54.172z"/>
      <path style="fill:#FFFFFF;" d="M270.133,54.172h22.2v5.148h-16.08v6.753h14.1v5.023h-14.1v8.146h16.208v5.106h-22.328V54.172z"/>
      <path style="fill:#FFFFFF;" d="M318.54,54.172h6.246l3.926,14.985c0.591,2.575,1.308,6.584,1.308,6.584h0.084
        c0,0,0.76-3.756,1.393-6.415l3.292-15.151h5.909l3.292,15.109c0.633,2.659,1.393,6.457,1.393,6.457h0.085
        c0,0,0.885-4.009,1.476-6.626l3.8-14.94h6.035l-8.609,30.176h-5.7l-3.671-16.165c-0.507-2.194-1.055-5.36-1.055-5.36h-0.083
        c0,0-0.464,3.166-0.971,5.36l-3.714,16.165h-5.655L318.54,54.172z"/>
      <path style="fill:#FFFFFF;" d="M373.953,54.172h6.331l10.636,30.176h-6.373l-2.026-6.415h-10.889l-1.984,6.415h-6.2L373.953,54.172
        z M373.067,73.248h7.977l-2.7-8.694c-0.507-1.6-1.224-4.643-1.224-4.643h-0.085c0,0-0.76,3.039-1.266,4.643L373.067,73.248z"/>
      <path style="fill:#FFFFFF;" d="M400.33,75.021h5.993c0.422,3.629,2.49,5.065,6.8,5.065c3.123,0,5.867-1.1,5.867-3.883
        c0-2.955-2.87-3.546-7.471-4.6c-5.4-1.223-10.34-2.659-10.34-8.947c0-5.951,4.854-9.032,11.564-9.032
        c6.8,0,11.185,3.334,11.649,9.412h-5.867c-0.337-3.039-2.7-4.559-5.824-4.559c-3.292,0-5.444,1.393-5.444,3.545
        c0,2.449,2.11,3.166,6.584,4.137c6.2,1.351,11.268,2.827,11.268,9.327c0,6.12-4.937,9.539-11.732,9.539
        C405.183,85.024,400.541,81.394,400.33,75.021z"/>
      <path style="fill:#FFFFFF;" d="M434.599,54.172h24.269v5.148h-9.074v25.028h-6.12V59.32h-9.075V54.172z"/>
      <path style="fill:#FFFFFF;" d="M470.599,54.172h22.2v5.148h-16.08v6.753h14.1v5.023h-14.1v8.146h16.208v5.106h-22.328V54.172z"/>
      <path style="fill:#FFFFFF;" d="M505.375,54.172h11.775c8.062,0,13.253,6.5,13.253,15.4c0,4.9-1.646,9.2-4.811,11.86
        c-2.536,2.014-5.713,3.048-8.949,2.912h-11.268V54.172z M516.221,79.072c5.741,0,7.978-3.5,7.978-9.5s-2.617-10.171-7.809-10.171
        h-4.895v19.672L516.221,79.072z"/>
      <path style="fill:#969696;" d="M556.821,69.323c0-9.032,5.656-15.742,14.562-15.742s14.518,6.71,14.518,15.742
        s-5.613,15.7-14.518,15.7S556.821,78.357,556.821,69.323z M579.655,69.323c0-6.035-2.7-10.72-8.23-10.72s-8.357,4.685-8.357,10.72
        c0,5.993,2.827,10.678,8.357,10.678S579.655,75.316,579.655,69.323z"/>
      <path style="fill:#969696;" d="M597.886,54.172h6.2l10.045,16.84c0.97,1.645,2.11,4.22,2.11,4.22h0.085
        c0,0-0.169-3.123-0.169-5.233V54.172h6.035v30.176h-5.909l-10.3-16.67c-0.971-1.605-2.152-4.179-2.152-4.179h-0.085
        c0,0,0.169,3.165,0.169,5.275v15.574h-6.035L597.886,54.172z"/>
      <path style="fill:#969696;" d="M651.23,54.172h11.776c8.061,0,13.253,6.5,13.253,15.4c0,4.9-1.647,9.2-4.812,11.86
        c-2.536,2.014-5.712,3.048-8.948,2.912H651.23V54.172z M662.077,79.072c5.74,0,7.977-3.5,7.977-9.5s-2.617-10.171-7.808-10.171
        h-4.9v19.672L662.077,79.072z"/>
      <path style="fill:#969696;" d="M688.243,54.172h22.2v5.148h-16.082v6.753h14.1v5.023h-14.1v8.146h16.207v5.106h-22.325V54.172z"/>
      <path style="fill:#969696;" d="M721.461,75.021h5.993c0.422,3.629,2.49,5.065,6.795,5.065c3.123,0,5.867-1.1,5.867-3.883
        c0-2.955-2.87-3.546-7.471-4.6c-5.4-1.223-10.34-2.659-10.34-8.947c0-5.951,4.854-9.032,11.564-9.032
        c6.8,0,11.185,3.334,11.649,9.412h-5.867c-0.337-3.039-2.7-4.559-5.824-4.559c-3.292,0-5.444,1.393-5.444,3.545
        c0,2.449,2.11,3.166,6.584,4.137c6.2,1.351,11.268,2.827,11.268,9.327c0,6.12-4.937,9.539-11.732,9.539
        C726.31,85.024,721.668,81.394,721.461,75.021z"/>
      <path style="fill:#969696;" d="M755.726,54.172h24.269v5.148h-9.074v25.028h-6.12V59.32h-9.075V54.172z"/>
      <path style="fill:#969696;" d="M791.768,54.172h6.12v30.176h-6.12V54.172z"/>
      <path style="fill:#969696;" d="M811.224,54.172h6.2l10.045,16.84c0.971,1.645,2.111,4.22,2.111,4.22h0.085
        c0,0-0.169-3.123-0.169-5.233V54.172h6.035v30.176h-5.909l-10.3-16.67c-0.971-1.605-2.152-4.179-2.152-4.179h-0.085
        c0,0,0.169,3.165,0.169,5.275v15.574h-6.036L811.224,54.172z"/>
      <path style="fill:#969696;" d="M856.719,72.7l-10.044-18.528h6.668l4.558,8.947c0.929,1.814,1.984,4.305,1.984,4.305h0.084
        c0,0,1.14-2.491,2.026-4.305l4.474-8.947h6.416L862.84,72.7v11.648h-6.121V72.7z"/>
    </g>
    <g class="-translate-x-[3.5rem] md:translate-x-0">
      <path style="fill:#FFFFFF;" d="M175.626,111.518c0.584,0.992,1.348,1.768,2.292,2.328c0.944,0.56,1.968,0.84,3.072,0.84
        c0.8,0,1.532-0.104,2.196-0.313c0.664-0.207,1.268-0.5,1.812-0.876c0.543-0.375,1.008-0.812,1.392-1.308v2.688
        c-0.704,0.624-1.472,1.089-2.304,1.393c-0.832,0.304-1.864,0.456-3.096,0.456c-1.168,0-2.26-0.213-3.276-0.637
        c-1.016-0.423-1.908-1.023-2.676-1.8c-0.768-0.775-1.368-1.691-1.8-2.748c-0.432-1.056-0.648-2.208-0.648-3.456
        s0.216-2.399,0.648-3.456c0.432-1.056,1.032-1.972,1.8-2.747c0.768-0.776,1.66-1.376,2.676-1.801
        c1.016-0.424,2.108-0.636,3.276-0.636c1.232,0,2.264,0.152,3.096,0.456c0.832,0.304,1.6,0.768,2.304,1.392v2.688
        c-0.384-0.496-0.848-0.932-1.392-1.308c-0.544-0.376-1.148-0.668-1.812-0.877c-0.664-0.207-1.396-0.312-2.196-0.312
        c-1.104,0-2.128,0.28-3.072,0.84c-0.944,0.561-1.708,1.332-2.292,2.316c-0.584,0.983-0.876,2.132-0.876,3.443
        C174.75,109.382,175.042,110.525,175.626,111.518z"/>
      <path style="fill:#FFFFFF;" d="M191.79,116.485h-1.92v-18.72h1.92V116.485z M196.362,107.701c-0.392-0.464-0.98-0.696-1.764-0.696
        c-0.56,0-1.052,0.12-1.476,0.36s-0.752,0.568-0.984,0.984s-0.348,0.888-0.348,1.416h-0.624c0-0.88,0.168-1.66,0.504-2.34
        c0.336-0.681,0.795-1.221,1.38-1.62c0.584-0.4,1.26-0.601,2.028-0.601c0.768,0,1.436,0.16,2.004,0.48
        c0.567,0.32,1.008,0.8,1.32,1.439c0.312,0.641,0.468,1.44,0.468,2.4v6.96h-1.92v-6.72
        C196.95,108.854,196.754,108.166,196.362,107.701z"/>
      <path style="fill:#FFFFFF;" d="M204.018,115.993c-0.792-0.487-1.408-1.164-1.848-2.027c-0.44-0.864-0.66-1.864-0.66-3
        c0-1.152,0.228-2.16,0.684-3.024s1.096-1.536,1.92-2.016c0.824-0.48,1.78-0.721,2.868-0.721c1.088,0,2.02,0.229,2.796,0.685
        c0.776,0.456,1.372,1.107,1.788,1.956c0.416,0.848,0.624,1.855,0.624,3.023c0,0.112-0.004,0.229-0.012,0.349
        c-0.008,0.119-0.012,0.195-0.012,0.228h-9.432v-1.632h7.92l-0.768,1.104c0.048-0.079,0.096-0.204,0.144-0.372
        c0.048-0.168,0.072-0.308,0.072-0.42c0-0.655-0.132-1.224-0.396-1.704c-0.264-0.479-0.628-0.855-1.092-1.128
        c-0.464-0.271-1.008-0.407-1.632-0.407c-0.736,0-1.364,0.155-1.884,0.468c-0.521,0.312-0.92,0.76-1.2,1.344
        c-0.28,0.584-0.428,1.3-0.444,2.148c0,0.863,0.136,1.6,0.408,2.208c0.272,0.607,0.672,1.071,1.2,1.392
        c0.528,0.32,1.167,0.48,1.92,0.48c0.752,0,1.416-0.164,1.992-0.492c0.576-0.328,1.063-0.828,1.464-1.5l1.56,0.983
        c-0.576,0.929-1.3,1.629-2.172,2.101s-1.9,0.708-3.084,0.708C205.717,116.726,204.81,116.481,204.018,115.993z"/>
      <path style="fill:#FFFFFF;" d="M216.186,113.005c0.328,0.593,0.776,1.061,1.344,1.404c0.568,0.345,1.212,0.517,1.932,0.517
        c0.592,0,1.148-0.093,1.668-0.276c0.52-0.184,0.972-0.432,1.356-0.744c0.384-0.312,0.656-0.644,0.816-0.996v2.4
        c-0.384,0.448-0.932,0.796-1.644,1.044s-1.444,0.372-2.196,0.372c-1.072,0-2.04-0.248-2.904-0.744
        c-0.864-0.496-1.548-1.18-2.052-2.052s-0.756-1.86-0.756-2.964c0-1.12,0.252-2.112,0.756-2.977s1.188-1.544,2.052-2.04
        c0.864-0.496,1.832-0.744,2.904-0.744c0.752,0,1.484,0.124,2.196,0.372s1.26,0.597,1.644,1.044v2.4
        c-0.16-0.368-0.432-0.704-0.816-1.008c-0.384-0.304-0.836-0.548-1.356-0.732c-0.52-0.184-1.076-0.276-1.668-0.276
        c-0.72,0-1.364,0.169-1.932,0.505c-0.568,0.336-1.016,0.804-1.344,1.403c-0.328,0.601-0.492,1.284-0.492,2.053
        C215.694,111.733,215.857,112.414,216.186,113.005z"/>
      <path style="fill:#FFFFFF;" d="M228.102,97.766v18.72h-1.92v-18.72H228.102z M234.582,105.445l-4.8,4.56l5.28,6.48h-2.4l-5.28-6.48
        l4.8-4.56H234.582z"/>
      <path style="fill:#FFFFFF;" d="M245.981,116.485h-1.92v-18.72h1.92V116.485z M250.553,107.701c-0.392-0.464-0.98-0.696-1.764-0.696
        c-0.56,0-1.052,0.12-1.476,0.36s-0.752,0.568-0.984,0.984s-0.348,0.888-0.348,1.416h-0.624c0-0.88,0.168-1.66,0.504-2.34
        c0.336-0.681,0.795-1.221,1.38-1.62c0.584-0.4,1.26-0.601,2.028-0.601c0.768,0,1.436,0.16,2.004,0.48
        c0.567,0.32,1.008,0.8,1.32,1.439c0.312,0.641,0.468,1.44,0.468,2.4v6.96h-1.92v-6.72
        C251.141,108.854,250.945,108.166,250.553,107.701z"/>
      <path style="fill:#FFFFFF;" d="M256.457,107.989c0.504-0.864,1.188-1.544,2.052-2.04c0.864-0.496,1.832-0.744,2.904-0.744
        c1.088,0,2.06,0.248,2.916,0.744s1.536,1.176,2.04,2.04c0.504,0.864,0.756,1.856,0.756,2.977c0,1.104-0.252,2.092-0.756,2.964
        c-0.504,0.872-1.184,1.556-2.04,2.052s-1.828,0.744-2.916,0.744c-1.072,0-2.04-0.248-2.904-0.744
        c-0.864-0.496-1.548-1.18-2.052-2.052c-0.504-0.872-0.756-1.86-0.756-2.964C255.701,109.846,255.953,108.854,256.457,107.989z
        M258.137,113.005c0.328,0.593,0.776,1.061,1.344,1.404c0.568,0.345,1.212,0.517,1.932,0.517c0.72,0,1.364-0.172,1.932-0.517
        c0.568-0.344,1.016-0.812,1.344-1.404c0.328-0.591,0.492-1.271,0.492-2.039c0-0.769-0.164-1.452-0.492-2.053
        c-0.328-0.6-0.776-1.067-1.344-1.403s-1.212-0.505-1.932-0.505c-0.72,0-1.364,0.169-1.932,0.505
        c-0.568,0.336-1.017,0.804-1.344,1.403c-0.328,0.601-0.492,1.284-0.492,2.053C257.645,111.733,257.809,112.414,258.137,113.005z"/>
      <path style="fill:#FFFFFF;" d="M269.884,105.445l2.76,7.176l3-8.016l3.216,8.063l2.784-7.224h2.16l-4.8,11.88l-3.336-8.04
        l-3.144,8.04l-4.8-11.88H269.884z"/>
      <path style="fill:#FFFFFF;" d="M308.044,116.485h-1.92v-6.72c0-0.944-0.172-1.641-0.516-2.088
        c-0.344-0.448-0.876-0.673-1.596-0.673c-0.528,0-0.984,0.108-1.368,0.324s-0.68,0.532-0.888,0.948s-0.312,0.912-0.312,1.488v6.72
        h-1.92v-6.72c0-0.944-0.172-1.641-0.516-2.088c-0.344-0.448-0.876-0.673-1.596-0.673c-0.528,0-0.984,0.108-1.368,0.324
        c-0.384,0.216-0.68,0.532-0.888,0.948c-0.208,0.416-0.312,0.912-0.312,1.488v6.72h-1.92v-11.04h1.92v1.608
        c0.336-0.624,0.756-1.088,1.26-1.393c0.504-0.304,1.1-0.456,1.788-0.456c0.768,0,1.428,0.181,1.98,0.54
        c0.552,0.36,0.956,0.868,1.212,1.524c0.384-0.688,0.864-1.204,1.44-1.548c0.576-0.344,1.232-0.517,1.968-0.517
        c0.752,0,1.392,0.168,1.92,0.504c0.528,0.336,0.932,0.828,1.212,1.477c0.28,0.647,0.42,1.428,0.42,2.34V116.485z"/>
      <path style="fill:#FFFFFF;" d="M314.164,114.205c0.4,0.48,0.984,0.721,1.752,0.721c0.56,0,1.052-0.116,1.476-0.349
        c0.424-0.231,0.752-0.556,0.984-0.972c0.231-0.416,0.348-0.896,0.348-1.44v-6.72h1.92v11.04h-1.92v-1.728
        c-0.368,0.656-0.824,1.147-1.368,1.476c-0.544,0.328-1.185,0.492-1.92,0.492c-1.152,0-2.072-0.393-2.76-1.176
        c-0.688-0.784-1.032-1.832-1.032-3.145v-6.96h1.92v6.72C313.564,113.046,313.764,113.726,314.164,114.205z"/>
      <path style="fill:#FFFFFF;" d="M325.6,113.005c0.328,0.593,0.776,1.061,1.344,1.404c0.568,0.345,1.212,0.517,1.932,0.517
        c0.592,0,1.148-0.093,1.668-0.276c0.52-0.184,0.972-0.432,1.356-0.744c0.384-0.312,0.656-0.644,0.816-0.996v2.4
        c-0.384,0.448-0.932,0.796-1.644,1.044s-1.444,0.372-2.196,0.372c-1.072,0-2.04-0.248-2.904-0.744
        c-0.864-0.496-1.548-1.18-2.052-2.052s-0.756-1.86-0.756-2.964c0-1.12,0.252-2.112,0.756-2.977s1.188-1.544,2.052-2.04
        c0.864-0.496,1.832-0.744,2.904-0.744c0.752,0,1.484,0.124,2.196,0.372s1.26,0.597,1.644,1.044v2.4
        c-0.16-0.368-0.432-0.704-0.816-1.008c-0.384-0.304-0.836-0.548-1.356-0.732c-0.52-0.184-1.076-0.276-1.668-0.276
        c-0.72,0-1.364,0.169-1.932,0.505c-0.568,0.336-1.016,0.804-1.344,1.403c-0.328,0.601-0.492,1.284-0.492,2.053
        C325.108,111.733,325.271,112.414,325.6,113.005z"/>
      <path style="fill:#FFFFFF;" d="M337.636,116.485h-1.92v-18.72h1.92V116.485z M342.208,107.701c-0.392-0.464-0.98-0.696-1.764-0.696
        c-0.56,0-1.052,0.12-1.476,0.36s-0.752,0.568-0.984,0.984s-0.348,0.888-0.348,1.416h-0.624c0-0.88,0.168-1.66,0.504-2.34
        c0.336-0.681,0.795-1.221,1.38-1.62c0.584-0.4,1.26-0.601,2.028-0.601c0.768,0,1.436,0.16,2.004,0.48
        c0.567,0.32,1.008,0.8,1.32,1.439c0.312,0.641,0.468,1.44,0.468,2.4v6.96h-1.92v-6.72
        C342.796,108.854,342.6,108.166,342.208,107.701z"/>
      <path style="fill:#FFFFFF;" d="M359.355,105.445v1.8h-5.52v-1.8H359.355z M357.555,101.605v14.88h-1.92v-14.88H357.555z"/>
      <path style="fill:#FFFFFF;" d="M361.671,99.949c0.264-0.256,0.572-0.384,0.924-0.384c0.368,0,0.68,0.128,0.936,0.384
        c0.255,0.256,0.384,0.568,0.384,0.937c0,0.352-0.128,0.659-0.384,0.924c-0.256,0.264-0.568,0.396-0.936,0.396
        c-0.352,0-0.66-0.132-0.924-0.396c-0.264-0.265-0.396-0.572-0.396-0.924C361.275,100.518,361.407,100.205,361.671,99.949z
        M363.555,105.445v11.04h-1.92v-11.04H363.555z"/>
      <path style="fill:#FFFFFF;" d="M382.635,116.485h-1.92v-6.72c0-0.944-0.172-1.641-0.516-2.088
        c-0.344-0.448-0.876-0.673-1.596-0.673c-0.528,0-0.984,0.108-1.368,0.324s-0.68,0.532-0.888,0.948s-0.312,0.912-0.312,1.488v6.72
        h-1.92v-6.72c0-0.944-0.172-1.641-0.516-2.088c-0.344-0.448-0.876-0.673-1.596-0.673c-0.528,0-0.984,0.108-1.368,0.324
        c-0.384,0.216-0.68,0.532-0.888,0.948c-0.208,0.416-0.312,0.912-0.312,1.488v6.72h-1.92v-11.04h1.92v1.608
        c0.336-0.624,0.756-1.088,1.26-1.393c0.504-0.304,1.1-0.456,1.788-0.456c0.768,0,1.428,0.181,1.98,0.54
        c0.552,0.36,0.956,0.868,1.212,1.524c0.384-0.688,0.864-1.204,1.44-1.548c0.576-0.344,1.232-0.517,1.968-0.517
        c0.752,0,1.392,0.168,1.92,0.504c0.528,0.336,0.932,0.828,1.212,1.477c0.28,0.647,0.42,1.428,0.42,2.34V116.485z"/>
      <path style="fill:#FFFFFF;" d="M387.783,115.993c-0.792-0.487-1.408-1.164-1.848-2.027c-0.44-0.864-0.66-1.864-0.66-3
        c0-1.152,0.228-2.16,0.684-3.024s1.096-1.536,1.92-2.016c0.824-0.48,1.78-0.721,2.868-0.721c1.088,0,2.02,0.229,2.796,0.685
        c0.776,0.456,1.372,1.107,1.788,1.956c0.416,0.848,0.624,1.855,0.624,3.023c0,0.112-0.004,0.229-0.012,0.349
        c-0.008,0.119-0.012,0.195-0.012,0.228h-9.432v-1.632h7.92l-0.768,1.104c0.048-0.079,0.096-0.204,0.144-0.372
        c0.048-0.168,0.072-0.308,0.072-0.42c0-0.655-0.132-1.224-0.396-1.704c-0.264-0.479-0.628-0.855-1.092-1.128
        c-0.464-0.271-1.008-0.407-1.632-0.407c-0.736,0-1.364,0.155-1.884,0.468c-0.521,0.312-0.92,0.76-1.2,1.344
        c-0.28,0.584-0.428,1.3-0.444,2.148c0,0.863,0.136,1.6,0.408,2.208c0.272,0.607,0.672,1.071,1.2,1.392
        c0.528,0.32,1.167,0.48,1.92,0.48c0.752,0,1.416-0.164,1.992-0.492c0.576-0.328,1.063-0.828,1.464-1.5l1.56,0.983
        c-0.576,0.929-1.3,1.629-2.172,2.101s-1.9,0.708-3.084,0.708C389.483,116.726,388.575,116.481,387.783,115.993z"/>
      <path style="fill:#FFFFFF;" d="M407.354,121.766h-2.16l3.024-6.84l-4.224-9.48h2.28l3.504,8.64l-0.792-0.096l3.408-8.544h2.16
        L407.354,121.766z"/>
      <path style="fill:#FFFFFF;" d="M415.791,107.989c0.504-0.864,1.188-1.544,2.052-2.04c0.864-0.496,1.832-0.744,2.904-0.744
        c1.088,0,2.06,0.248,2.916,0.744s1.536,1.176,2.04,2.04c0.504,0.864,0.756,1.856,0.756,2.977c0,1.104-0.252,2.092-0.756,2.964
        c-0.504,0.872-1.184,1.556-2.04,2.052s-1.828,0.744-2.916,0.744c-1.072,0-2.04-0.248-2.904-0.744
        c-0.864-0.496-1.548-1.18-2.052-2.052c-0.504-0.872-0.756-1.86-0.756-2.964C415.034,109.846,415.286,108.854,415.791,107.989z
        M417.47,113.005c0.328,0.593,0.776,1.061,1.344,1.404c0.568,0.345,1.212,0.517,1.932,0.517c0.72,0,1.364-0.172,1.932-0.517
        c0.568-0.344,1.016-0.812,1.344-1.404c0.328-0.591,0.492-1.271,0.492-2.039c0-0.769-0.164-1.452-0.492-2.053
        c-0.328-0.6-0.776-1.067-1.344-1.403s-1.212-0.505-1.932-0.505c-0.72,0-1.364,0.169-1.932,0.505
        c-0.568,0.336-1.017,0.804-1.344,1.403c-0.328,0.601-0.492,1.284-0.492,2.053C416.979,111.733,417.142,112.414,417.47,113.005z"/>
      <path style="fill:#FFFFFF;" d="M431.618,114.205c0.4,0.48,0.984,0.721,1.752,0.721c0.56,0,1.052-0.116,1.476-0.349
        c0.424-0.231,0.752-0.556,0.984-0.972c0.231-0.416,0.348-0.896,0.348-1.44v-6.72h1.919v11.04h-1.919v-1.728
        c-0.368,0.656-0.824,1.147-1.368,1.476c-0.544,0.328-1.185,0.492-1.92,0.492c-1.152,0-2.072-0.393-2.76-1.176
        c-0.688-0.784-1.032-1.832-1.032-3.145v-6.96h1.92v6.72C431.019,113.046,431.218,113.726,431.618,114.205z"/>
      <path style="fill:#FFFFFF;" d="M450.062,114.157c0.2,0.305,0.48,0.532,0.84,0.685c0.36,0.151,0.772,0.228,1.236,0.228
        c0.592,0,1.124-0.12,1.596-0.36c0.472-0.239,0.848-0.579,1.128-1.02s0.42-0.948,0.42-1.524l0.385,1.44
        c0,0.704-0.213,1.288-0.637,1.752s-0.944,0.808-1.56,1.032c-0.616,0.224-1.229,0.336-1.836,0.336c-0.672,0-1.3-0.141-1.884-0.42
        c-0.585-0.28-1.053-0.685-1.404-1.212c-0.353-0.528-0.528-1.168-0.528-1.92c0-1.072,0.38-1.933,1.141-2.58
        c0.76-0.648,1.819-0.973,3.18-0.973c0.8,0,1.468,0.093,2.004,0.276s0.964,0.393,1.284,0.624c0.319,0.232,0.536,0.42,0.647,0.563
        v1.128c-0.56-0.384-1.136-0.659-1.728-0.827s-1.224-0.252-1.896-0.252c-0.624,0-1.132,0.08-1.523,0.239
        c-0.393,0.16-0.685,0.385-0.876,0.672c-0.192,0.288-0.288,0.633-0.288,1.032C449.762,113.493,449.861,113.854,450.062,114.157z
        M448.538,106.573c0.415-0.304,0.987-0.607,1.716-0.912c0.728-0.304,1.604-0.456,2.628-0.456c0.864,0,1.62,0.132,2.268,0.396
        c0.648,0.264,1.152,0.644,1.513,1.14c0.359,0.496,0.539,1.104,0.539,1.824v7.92h-1.92v-7.656c0-0.384-0.063-0.699-0.191-0.947
        c-0.129-0.248-0.309-0.444-0.54-0.589c-0.232-0.144-0.5-0.247-0.804-0.312c-0.305-0.063-0.624-0.096-0.96-0.096
        c-0.545,0-1.036,0.063-1.477,0.191c-0.44,0.129-0.813,0.28-1.116,0.456c-0.304,0.177-0.544,0.328-0.72,0.456L448.538,106.573z"/>
      <path style="fill:#FFFFFF;" d="M466.921,107.701c-0.4-0.464-0.984-0.696-1.752-0.696c-0.561,0-1.053,0.117-1.477,0.349
        c-0.424,0.232-0.752,0.556-0.983,0.972c-0.232,0.416-0.349,0.896-0.349,1.44v6.72h-1.92v-11.04h1.92v1.729
        c0.368-0.673,0.828-1.168,1.38-1.488c0.553-0.32,1.188-0.48,1.908-0.48c1.168,0,2.092,0.389,2.772,1.164
        c0.68,0.776,1.02,1.828,1.02,3.156v6.96h-1.92v-6.72C467.521,108.854,467.32,108.166,466.921,107.701z"/>
      <path style="fill:#FFFFFF;" d="M472.825,107.882c0.496-0.856,1.16-1.517,1.992-1.98s1.743-0.696,2.735-0.696
        c0.929,0,1.736,0.232,2.425,0.696c0.688,0.464,1.228,1.124,1.62,1.98c0.392,0.855,0.588,1.884,0.588,3.084
        c0,1.184-0.196,2.208-0.588,3.071c-0.393,0.864-0.933,1.528-1.62,1.992c-0.688,0.464-1.496,0.696-2.425,0.696
        c-0.992,0-1.903-0.232-2.735-0.696s-1.496-1.128-1.992-1.992c-0.496-0.863-0.744-1.888-0.744-3.071
        C472.081,109.766,472.329,108.737,472.825,107.882z M474.541,113.125c0.344,0.593,0.804,1.041,1.38,1.345s1.2,0.456,1.872,0.456
        c0.561,0,1.112-0.152,1.656-0.456s0.992-0.752,1.344-1.345c0.352-0.592,0.528-1.312,0.528-2.159c0-0.849-0.177-1.568-0.528-2.16
        s-0.8-1.04-1.344-1.345c-0.544-0.304-1.096-0.456-1.656-0.456c-0.672,0-1.296,0.152-1.872,0.456
        c-0.576,0.305-1.036,0.753-1.38,1.345s-0.516,1.312-0.516,2.16C474.025,111.813,474.197,112.533,474.541,113.125z M483.241,97.766
        v18.72h-1.92v-18.72H483.241z"/>
      <path style="fill:#FFFFFF;" d="M495.48,121.766h-2.16l3.023-6.84l-4.224-9.48h2.28l3.504,8.64l-0.792-0.096l3.408-8.544h2.159
        L495.48,121.766z"/>
      <path style="fill:#FFFFFF;" d="M503.916,107.989c0.504-0.864,1.188-1.544,2.053-2.04c0.863-0.496,1.831-0.744,2.903-0.744
        c1.088,0,2.061,0.248,2.916,0.744c0.856,0.496,1.536,1.176,2.04,2.04s0.756,1.856,0.756,2.977c0,1.104-0.252,2.092-0.756,2.964
        s-1.184,1.556-2.04,2.052c-0.855,0.496-1.828,0.744-2.916,0.744c-1.072,0-2.04-0.248-2.903-0.744
        c-0.864-0.496-1.549-1.18-2.053-2.052s-0.756-1.86-0.756-2.964C503.16,109.846,503.412,108.854,503.916,107.989z M505.597,113.005
        c0.327,0.593,0.775,1.061,1.344,1.404c0.567,0.345,1.212,0.517,1.932,0.517c0.721,0,1.364-0.172,1.933-0.517
        c0.567-0.344,1.016-0.812,1.344-1.404c0.327-0.591,0.492-1.271,0.492-2.039c0-0.769-0.165-1.452-0.492-2.053
        c-0.328-0.6-0.776-1.067-1.344-1.403c-0.568-0.336-1.212-0.505-1.933-0.505c-0.72,0-1.364,0.169-1.932,0.505
        c-0.568,0.336-1.017,0.804-1.344,1.403c-0.328,0.601-0.492,1.284-0.492,2.053C505.104,111.733,505.269,112.414,505.597,113.005z"/>
      <path style="fill:#FFFFFF;" d="M519.744,114.205c0.399,0.48,0.983,0.721,1.752,0.721c0.56,0,1.052-0.116,1.476-0.349
        c0.424-0.231,0.752-0.556,0.984-0.972c0.231-0.416,0.348-0.896,0.348-1.44v-6.72h1.92v11.04h-1.92v-1.728
        c-0.368,0.656-0.824,1.147-1.368,1.476s-1.184,0.492-1.92,0.492c-1.151,0-2.072-0.393-2.76-1.176
        c-0.688-0.784-1.032-1.832-1.032-3.145v-6.96h1.92v6.72C519.144,113.046,519.344,113.726,519.744,114.205z"/>
      <path style="fill:#FFFFFF;" d="M531.743,116.485h-1.92v-11.04h1.92V116.485z M534.384,107.138
        c-0.208-0.088-0.473-0.133-0.792-0.133c-0.48,0-0.856,0.12-1.128,0.36c-0.272,0.24-0.461,0.568-0.564,0.984
        c-0.104,0.416-0.156,0.888-0.156,1.416h-0.863c0-0.88,0.155-1.66,0.468-2.34c0.312-0.681,0.716-1.221,1.212-1.62
        c0.496-0.4,1-0.601,1.512-0.601c0.399,0,0.771,0.053,1.116,0.156c0.344,0.104,0.66,0.309,0.948,0.612l-1.057,1.584
        C534.823,107.365,534.591,107.226,534.384,107.138z"/>
      <path style="fill:#FFFFFF;" d="M549.936,105.445v1.8h-5.521v-1.8H549.936z M549.179,99.554c-0.216-0.072-0.42-0.108-0.611-0.108
        c-0.24,0-0.444,0.068-0.612,0.204s-0.3,0.364-0.396,0.684c-0.096,0.32-0.144,0.744-0.144,1.272v14.88h-1.92v-15.12
        c0-0.88,0.128-1.6,0.384-2.16c0.256-0.56,0.62-0.979,1.092-1.26c0.472-0.279,1.044-0.42,1.717-0.42
        c0.352,0,0.663,0.048,0.936,0.144c0.271,0.097,0.508,0.217,0.708,0.36c0.2,0.145,0.355,0.296,0.468,0.456l-1.056,1.584
        C549.583,99.798,549.396,99.625,549.179,99.554z"/>
      <path style="fill:#FFFFFF;" d="M554.039,116.485h-1.92v-11.04h1.92V116.485z M556.68,107.138c-0.208-0.088-0.473-0.133-0.792-0.133
        c-0.48,0-0.856,0.12-1.128,0.36c-0.272,0.24-0.461,0.568-0.564,0.984c-0.104,0.416-0.156,0.888-0.156,1.416h-0.863
        c0-0.88,0.155-1.66,0.468-2.34c0.312-0.681,0.716-1.221,1.212-1.62c0.496-0.4,1-0.601,1.512-0.601c0.399,0,0.771,0.053,1.116,0.156
        c0.344,0.104,0.66,0.309,0.948,0.612l-1.057,1.584C557.119,107.365,556.887,107.226,556.68,107.138z"/>
      <path style="fill:#FFFFFF;" d="M560.747,99.949c0.264-0.256,0.571-0.384,0.924-0.384c0.368,0,0.68,0.128,0.936,0.384
        s0.385,0.568,0.385,0.937c0,0.352-0.129,0.659-0.385,0.924c-0.256,0.264-0.567,0.396-0.936,0.396c-0.353,0-0.66-0.132-0.924-0.396
        c-0.265-0.265-0.396-0.572-0.396-0.924C560.351,100.518,560.482,100.205,560.747,99.949z M562.631,105.445v11.04h-1.92v-11.04
        H562.631z"/>
      <path style="fill:#FFFFFF;" d="M568.138,115.993c-0.792-0.487-1.408-1.164-1.848-2.027c-0.44-0.864-0.66-1.864-0.66-3
        c0-1.152,0.229-2.16,0.685-3.024s1.096-1.536,1.92-2.016c0.823-0.48,1.779-0.721,2.867-0.721s2.021,0.229,2.797,0.685
        c0.775,0.456,1.371,1.107,1.788,1.956c0.415,0.848,0.623,1.855,0.623,3.023c0,0.112-0.004,0.229-0.012,0.349
        c-0.008,0.119-0.012,0.195-0.012,0.228h-9.432v-1.632h7.92l-0.769,1.104c0.048-0.079,0.096-0.204,0.145-0.372
        c0.048-0.168,0.071-0.308,0.071-0.42c0-0.655-0.132-1.224-0.396-1.704c-0.264-0.479-0.628-0.855-1.092-1.128
        c-0.465-0.271-1.009-0.407-1.633-0.407c-0.735,0-1.364,0.155-1.884,0.468c-0.52,0.312-0.92,0.76-1.2,1.344
        c-0.279,0.584-0.428,1.3-0.443,2.148c0,0.863,0.136,1.6,0.408,2.208c0.271,0.607,0.672,1.071,1.199,1.392
        c0.528,0.32,1.168,0.48,1.92,0.48s1.416-0.164,1.992-0.492s1.064-0.828,1.464-1.5l1.561,0.983c-0.576,0.929-1.3,1.629-2.172,2.101
        s-1.9,0.708-3.084,0.708C569.838,116.726,568.93,116.481,568.138,115.993z"/>
      <path style="fill:#FFFFFF;" d="M585.431,107.701c-0.4-0.464-0.984-0.696-1.752-0.696c-0.561,0-1.053,0.117-1.477,0.349
        c-0.424,0.232-0.752,0.556-0.983,0.972c-0.232,0.416-0.349,0.896-0.349,1.44v6.72h-1.92v-11.04h1.92v1.729
        c0.368-0.673,0.828-1.168,1.38-1.488c0.553-0.32,1.188-0.48,1.908-0.48c1.168,0,2.092,0.389,2.772,1.164
        c0.68,0.776,1.02,1.828,1.02,3.156v6.96h-1.92v-6.72C586.03,108.854,585.83,108.166,585.431,107.701z"/>
      <path style="fill:#FFFFFF;" d="M591.334,107.882c0.496-0.856,1.16-1.517,1.992-1.98s1.743-0.696,2.735-0.696
        c0.929,0,1.736,0.232,2.425,0.696c0.688,0.464,1.228,1.124,1.62,1.98c0.392,0.855,0.588,1.884,0.588,3.084
        c0,1.184-0.196,2.208-0.588,3.071c-0.393,0.864-0.933,1.528-1.62,1.992c-0.688,0.464-1.496,0.696-2.425,0.696
        c-0.992,0-1.903-0.232-2.735-0.696s-1.496-1.128-1.992-1.992c-0.496-0.863-0.744-1.888-0.744-3.071
        C590.59,109.766,590.838,108.737,591.334,107.882z M593.05,113.125c0.344,0.593,0.804,1.041,1.38,1.345s1.2,0.456,1.872,0.456
        c0.561,0,1.112-0.152,1.656-0.456s0.992-0.752,1.344-1.345c0.352-0.592,0.528-1.312,0.528-2.159c0-0.849-0.177-1.568-0.528-2.16
        s-0.8-1.04-1.344-1.345c-0.544-0.304-1.096-0.456-1.656-0.456c-0.672,0-1.296,0.152-1.872,0.456
        c-0.576,0.305-1.036,0.753-1.38,1.345s-0.516,1.312-0.516,2.16C592.534,111.813,592.706,112.533,593.05,113.125z M601.75,97.766
        v18.72h-1.92v-18.72H601.75z"/>
      <path style="fill:#FFFFFF;" d="M606.455,114.037c0.287,0.288,0.619,0.517,0.995,0.685s0.788,0.252,1.236,0.252
        c0.544,0,0.972-0.124,1.284-0.372c0.312-0.248,0.468-0.588,0.468-1.021c0-0.384-0.124-0.708-0.372-0.972s-0.563-0.488-0.947-0.672
        c-0.385-0.184-0.792-0.356-1.225-0.517c-0.479-0.191-0.964-0.42-1.452-0.684s-0.896-0.607-1.224-1.032
        c-0.328-0.424-0.492-0.956-0.492-1.596c0-0.656,0.172-1.2,0.516-1.632c0.345-0.433,0.792-0.752,1.345-0.96
        c0.552-0.208,1.124-0.313,1.716-0.313s1.147,0.097,1.668,0.288c0.52,0.192,0.979,0.448,1.38,0.769
        c0.399,0.32,0.712,0.688,0.937,1.104l-1.536,0.984c-0.305-0.416-0.685-0.76-1.141-1.032c-0.456-0.271-0.972-0.408-1.548-0.408
        c-0.4,0-0.744,0.088-1.032,0.265c-0.288,0.176-0.432,0.447-0.432,0.815c0,0.288,0.111,0.544,0.336,0.769
        c0.224,0.224,0.512,0.424,0.864,0.6c0.352,0.176,0.72,0.344,1.104,0.504c0.64,0.256,1.225,0.532,1.752,0.828
        c0.528,0.296,0.948,0.652,1.261,1.068c0.312,0.416,0.468,0.952,0.468,1.607c0,0.96-0.349,1.761-1.044,2.4
        c-0.696,0.64-1.612,0.96-2.748,0.96c-0.736,0-1.4-0.132-1.992-0.396c-0.592-0.264-1.092-0.604-1.5-1.02s-0.716-0.84-0.924-1.272
        l1.512-0.936C605.91,113.438,606.167,113.749,606.455,114.037z"/>
      <path style="fill:#FFFFFF;" d="M623.326,114.037c0.287,0.288,0.619,0.517,0.995,0.685s0.788,0.252,1.236,0.252
        c0.544,0,0.972-0.124,1.284-0.372c0.312-0.248,0.468-0.588,0.468-1.021c0-0.384-0.124-0.708-0.372-0.972s-0.563-0.488-0.947-0.672
        c-0.385-0.184-0.792-0.356-1.225-0.517c-0.479-0.191-0.964-0.42-1.452-0.684s-0.896-0.607-1.224-1.032
        c-0.328-0.424-0.492-0.956-0.492-1.596c0-0.656,0.172-1.2,0.516-1.632c0.345-0.433,0.792-0.752,1.345-0.96
        c0.552-0.208,1.124-0.313,1.716-0.313s1.147,0.097,1.668,0.288c0.52,0.192,0.979,0.448,1.38,0.769
        c0.399,0.32,0.712,0.688,0.937,1.104l-1.536,0.984c-0.305-0.416-0.685-0.76-1.141-1.032c-0.456-0.271-0.972-0.408-1.548-0.408
        c-0.4,0-0.744,0.088-1.032,0.265c-0.288,0.176-0.432,0.447-0.432,0.815c0,0.288,0.111,0.544,0.336,0.769
        c0.224,0.224,0.512,0.424,0.864,0.6c0.352,0.176,0.72,0.344,1.104,0.504c0.64,0.256,1.225,0.532,1.752,0.828
        c0.528,0.296,0.948,0.652,1.261,1.068c0.312,0.416,0.468,0.952,0.468,1.607c0,0.96-0.349,1.761-1.044,2.4
        c-0.696,0.64-1.612,0.96-2.748,0.96c-0.736,0-1.4-0.132-1.992-0.396c-0.592-0.264-1.092-0.604-1.5-1.02s-0.716-0.84-0.924-1.272
        l1.512-0.936C622.781,113.438,623.038,113.749,623.326,114.037z"/>
      <path style="fill:#FFFFFF;" d="M631.653,121.766v-16.32h1.92v16.32H631.653z M642.069,114.037
        c-0.496,0.864-1.156,1.528-1.979,1.992c-0.824,0.464-1.74,0.696-2.748,0.696c-0.912,0-1.716-0.232-2.412-0.696
        s-1.24-1.128-1.632-1.992c-0.393-0.863-0.588-1.888-0.588-3.071c0-1.2,0.195-2.229,0.588-3.084c0.392-0.856,0.936-1.517,1.632-1.98
        s1.5-0.696,2.412-0.696c1.008,0,1.924,0.232,2.748,0.696c0.823,0.464,1.483,1.124,1.979,1.98c0.496,0.855,0.744,1.884,0.744,3.084
        C642.813,112.149,642.565,113.174,642.069,114.037z M640.354,108.806c-0.344-0.592-0.8-1.04-1.368-1.345
        c-0.567-0.304-1.196-0.456-1.884-0.456c-0.561,0-1.112,0.152-1.656,0.456c-0.544,0.305-0.992,0.753-1.344,1.345
        c-0.353,0.592-0.528,1.312-0.528,2.16c0,0.848,0.176,1.567,0.528,2.159c0.352,0.593,0.8,1.041,1.344,1.345s1.096,0.456,1.656,0.456
        c0.688,0,1.316-0.152,1.884-0.456c0.568-0.304,1.024-0.752,1.368-1.345c0.344-0.592,0.517-1.312,0.517-2.159
        C640.87,110.117,640.697,109.397,640.354,108.806z"/>
      <path style="fill:#FFFFFF;" d="M647.001,115.993c-0.792-0.487-1.408-1.164-1.848-2.027c-0.44-0.864-0.66-1.864-0.66-3
        c0-1.152,0.229-2.16,0.685-3.024s1.096-1.536,1.92-2.016c0.823-0.48,1.779-0.721,2.867-0.721s2.021,0.229,2.797,0.685
        c0.775,0.456,1.371,1.107,1.788,1.956c0.415,0.848,0.623,1.855,0.623,3.023c0,0.112-0.004,0.229-0.012,0.349
        c-0.008,0.119-0.012,0.195-0.012,0.228h-9.432v-1.632h7.92l-0.769,1.104c0.048-0.079,0.096-0.204,0.145-0.372
        c0.048-0.168,0.071-0.308,0.071-0.42c0-0.655-0.132-1.224-0.396-1.704c-0.264-0.479-0.628-0.855-1.092-1.128
        c-0.465-0.271-1.009-0.407-1.633-0.407c-0.735,0-1.364,0.155-1.884,0.468c-0.52,0.312-0.92,0.76-1.2,1.344
        c-0.279,0.584-0.428,1.3-0.443,2.148c0,0.863,0.136,1.6,0.408,2.208c0.271,0.607,0.672,1.071,1.199,1.392
        c0.528,0.32,1.168,0.48,1.92,0.48s1.416-0.164,1.992-0.492s1.064-0.828,1.464-1.5l1.561,0.983c-0.576,0.929-1.3,1.629-2.172,2.101
        s-1.9,0.708-3.084,0.708C648.701,116.726,647.793,116.481,647.001,115.993z"/>
      <path style="fill:#FFFFFF;" d="M664.294,107.701c-0.4-0.464-0.984-0.696-1.752-0.696c-0.561,0-1.053,0.117-1.477,0.349
        c-0.424,0.232-0.752,0.556-0.983,0.972c-0.232,0.416-0.349,0.896-0.349,1.44v6.72h-1.92v-11.04h1.92v1.729
        c0.368-0.673,0.828-1.168,1.38-1.488c0.553-0.32,1.188-0.48,1.908-0.48c1.168,0,2.092,0.389,2.772,1.164
        c0.68,0.776,1.02,1.828,1.02,3.156v6.96h-1.92v-6.72C664.894,108.854,664.693,108.166,664.294,107.701z"/>
      <path style="fill:#FFFFFF;" d="M674.253,105.445v1.8h-5.52v-1.8H674.253z M672.453,101.605v14.88h-1.92v-14.88H672.453z"/>
      <path style="fill:#FFFFFF;" d="M683.169,107.989c0.504-0.864,1.188-1.544,2.053-2.04c0.863-0.496,1.831-0.744,2.903-0.744
        c1.088,0,2.061,0.248,2.916,0.744c0.856,0.496,1.536,1.176,2.04,2.04s0.756,1.856,0.756,2.977c0,1.104-0.252,2.092-0.756,2.964
        s-1.184,1.556-2.04,2.052c-0.855,0.496-1.828,0.744-2.916,0.744c-1.072,0-2.04-0.248-2.903-0.744
        c-0.864-0.496-1.549-1.18-2.053-2.052s-0.756-1.86-0.756-2.964C682.413,109.846,682.665,108.854,683.169,107.989z M684.85,113.005
        c0.327,0.593,0.775,1.061,1.344,1.404c0.567,0.345,1.212,0.517,1.932,0.517c0.721,0,1.364-0.172,1.933-0.517
        c0.567-0.344,1.016-0.812,1.344-1.404c0.327-0.591,0.492-1.271,0.492-2.039c0-0.769-0.165-1.452-0.492-2.053
        c-0.328-0.6-0.776-1.067-1.344-1.403c-0.568-0.336-1.212-0.505-1.933-0.505c-0.72,0-1.364,0.169-1.932,0.505
        c-0.568,0.336-1.017,0.804-1.344,1.403c-0.328,0.601-0.492,1.284-0.492,2.053C684.357,111.733,684.521,112.414,684.85,113.005z"/>
      <path style="fill:#FFFFFF;" d="M702.957,107.701c-0.4-0.464-0.984-0.696-1.752-0.696c-0.561,0-1.053,0.117-1.477,0.349
        c-0.424,0.232-0.752,0.556-0.983,0.972c-0.232,0.416-0.349,0.896-0.349,1.44v6.72h-1.92v-11.04h1.92v1.729
        c0.368-0.673,0.828-1.168,1.38-1.488c0.553-0.32,1.188-0.48,1.908-0.48c1.168,0,2.092,0.389,2.772,1.164
        c0.68,0.776,1.02,1.828,1.02,3.156v6.96h-1.92v-6.72C703.557,108.854,703.356,108.166,702.957,107.701z"/>
      <path style="fill:#FFFFFF;" d="M718.437,99.686v16.8h-2.04v-16.8H718.437z M717.524,116.485v-2.04h3.912
        c0.944,0,1.808-0.147,2.592-0.444c0.784-0.296,1.456-0.72,2.017-1.271c0.56-0.552,0.991-1.22,1.296-2.004
        c0.304-0.784,0.456-1.664,0.456-2.641c0-0.976-0.152-1.855-0.456-2.64c-0.305-0.784-0.736-1.452-1.296-2.004
        c-0.561-0.552-1.232-0.976-2.017-1.272c-0.784-0.296-1.647-0.443-2.592-0.443h-3.912v-2.04h3.912c1.632,0,3.08,0.352,4.344,1.056
        s2.256,1.685,2.977,2.94c0.72,1.256,1.08,2.724,1.08,4.403c0,1.681-0.36,3.148-1.08,4.404c-0.721,1.256-1.713,2.236-2.977,2.94
        s-2.712,1.056-4.344,1.056H717.524z"/>
      <path style="fill:#FFFFFF;" d="M734.144,115.993c-0.792-0.487-1.408-1.164-1.848-2.027c-0.44-0.864-0.66-1.864-0.66-3
        c0-1.152,0.229-2.16,0.685-3.024s1.096-1.536,1.92-2.016c0.823-0.48,1.779-0.721,2.867-0.721s2.021,0.229,2.797,0.685
        c0.775,0.456,1.371,1.107,1.788,1.956c0.415,0.848,0.623,1.855,0.623,3.023c0,0.112-0.004,0.229-0.012,0.349
        c-0.008,0.119-0.012,0.195-0.012,0.228h-9.432v-1.632h7.92l-0.769,1.104c0.048-0.079,0.096-0.204,0.145-0.372
        c0.048-0.168,0.071-0.308,0.071-0.42c0-0.655-0.132-1.224-0.396-1.704c-0.264-0.479-0.628-0.855-1.092-1.128
        c-0.465-0.271-1.009-0.407-1.633-0.407c-0.735,0-1.364,0.155-1.884,0.468c-0.52,0.312-0.92,0.76-1.2,1.344
        c-0.279,0.584-0.428,1.3-0.443,2.148c0,0.863,0.136,1.6,0.408,2.208c0.271,0.607,0.672,1.071,1.199,1.392
        c0.528,0.32,1.168,0.48,1.92,0.48s1.416-0.164,1.992-0.492s1.064-0.828,1.464-1.5l1.561,0.983c-0.576,0.929-1.3,1.629-2.172,2.101
        s-1.9,0.708-3.084,0.708C735.844,116.726,734.936,116.481,734.144,115.993z"/>
      <path style="fill:#FFFFFF;" d="M746.181,114.037c0.287,0.288,0.619,0.517,0.995,0.685s0.788,0.252,1.236,0.252
        c0.544,0,0.972-0.124,1.284-0.372c0.312-0.248,0.468-0.588,0.468-1.021c0-0.384-0.124-0.708-0.372-0.972s-0.563-0.488-0.947-0.672
        c-0.385-0.184-0.792-0.356-1.225-0.517c-0.479-0.191-0.964-0.42-1.452-0.684s-0.896-0.607-1.224-1.032
        c-0.328-0.424-0.492-0.956-0.492-1.596c0-0.656,0.172-1.2,0.516-1.632c0.345-0.433,0.792-0.752,1.345-0.96
        c0.552-0.208,1.124-0.313,1.716-0.313s1.147,0.097,1.668,0.288c0.52,0.192,0.979,0.448,1.38,0.769
        c0.399,0.32,0.712,0.688,0.937,1.104l-1.536,0.984c-0.305-0.416-0.685-0.76-1.141-1.032c-0.456-0.271-0.972-0.408-1.548-0.408
        c-0.4,0-0.744,0.088-1.032,0.265c-0.288,0.176-0.432,0.447-0.432,0.815c0,0.288,0.111,0.544,0.336,0.769
        c0.224,0.224,0.512,0.424,0.864,0.6c0.352,0.176,0.72,0.344,1.104,0.504c0.64,0.256,1.225,0.532,1.752,0.828
        c0.528,0.296,0.948,0.652,1.261,1.068c0.312,0.416,0.468,0.952,0.468,1.607c0,0.96-0.349,1.761-1.044,2.4
        c-0.696,0.64-1.612,0.96-2.748,0.96c-0.736,0-1.4-0.132-1.992-0.396c-0.592-0.264-1.092-0.604-1.5-1.02s-0.716-0.84-0.924-1.272
        l1.512-0.936C745.636,113.438,745.893,113.749,746.181,114.037z"/>
      <path style="fill:#FFFFFF;" d="M758.468,105.445v1.8h-5.52v-1.8H758.468z M756.668,101.605v14.88h-1.92v-14.88H756.668z"/>
      <path style="fill:#FFFFFF;" d="M760.784,99.949c0.264-0.256,0.571-0.384,0.924-0.384c0.368,0,0.68,0.128,0.936,0.384
        s0.385,0.568,0.385,0.937c0,0.352-0.129,0.659-0.385,0.924c-0.256,0.264-0.567,0.396-0.936,0.396c-0.353,0-0.66-0.132-0.924-0.396
        c-0.265-0.265-0.396-0.572-0.396-0.924C760.388,100.518,760.52,100.205,760.784,99.949z M762.668,105.445v11.04h-1.92v-11.04
        H762.668z"/>
      <path style="fill:#FFFFFF;" d="M773.108,107.701c-0.4-0.464-0.984-0.696-1.752-0.696c-0.561,0-1.053,0.117-1.477,0.349
        c-0.424,0.232-0.752,0.556-0.983,0.972c-0.232,0.416-0.349,0.896-0.349,1.44v6.72h-1.92v-11.04h1.92v1.729
        c0.368-0.673,0.828-1.168,1.38-1.488c0.553-0.32,1.188-0.48,1.908-0.48c1.168,0,2.092,0.389,2.772,1.164
        c0.68,0.776,1.02,1.828,1.02,3.156v6.96h-1.92v-6.72C773.708,108.854,773.508,108.166,773.108,107.701z"/>
      <path style="fill:#FFFFFF;" d="M780.668,121.766h-2.16l3.023-6.84l-4.224-9.48h2.28l3.504,8.64l-0.792-0.096l3.408-8.544h2.159
        L780.668,121.766z"/>
      <path style="fill:#FFFFFF;" d="M803.491,108.014c0.368-0.4,0.685-0.796,0.948-1.188c0.264-0.392,0.464-0.792,0.6-1.2
        c0.136-0.407,0.204-0.836,0.204-1.283c0-0.368-0.072-0.729-0.216-1.08c-0.144-0.353-0.356-0.673-0.636-0.96
        c-0.28-0.288-0.62-0.521-1.021-0.696c-0.399-0.176-0.855-0.264-1.368-0.264c-0.72,0-1.34,0.164-1.859,0.491
        c-0.521,0.328-0.916,0.801-1.188,1.416c-0.271,0.616-0.407,1.349-0.407,2.196h-2.04c0-1.2,0.216-2.252,0.647-3.156
        c0.432-0.903,1.06-1.607,1.884-2.111s1.813-0.757,2.964-0.757c0.928,0,1.729,0.156,2.4,0.469c0.672,0.312,1.224,0.712,1.656,1.199
        c0.432,0.488,0.752,1.013,0.96,1.572c0.208,0.561,0.312,1.097,0.312,1.608c0,0.864-0.208,1.72-0.624,2.567
        c-0.416,0.849-0.952,1.608-1.607,2.28l-5.353,5.448h7.656v1.92h-11.855L803.491,108.014z"/>
      <path style="fill:#FFFFFF;" d="M811.398,114.589c0.265-0.255,0.572-0.384,0.924-0.384c0.368,0,0.681,0.129,0.937,0.384
        c0.256,0.257,0.384,0.568,0.384,0.937c0,0.353-0.128,0.66-0.384,0.924s-0.568,0.396-0.937,0.396c-0.352,0-0.659-0.133-0.924-0.396
        c-0.264-0.264-0.396-0.571-0.396-0.924C811.003,115.157,811.135,114.846,811.398,114.589z M813.522,99.686l-0.479,12h-1.44
        l-0.479-12H813.522z"/>
    </g>
    <g class="hidden md:block">
      <path style="fill:#6C82E9;" d="M58.736,69.807c6.585,8.019,18.423,9.183,26.443,2.598c0.949-0.779,1.819-1.649,2.598-2.598H58.736z"
        />
      <path style="fill:#6C82E9;" d="M87.777,99.194c-6.585-8.02-18.425-9.183-26.444-2.598c-0.949,0.779-1.819,1.649-2.598,2.598H87.777z
        "/>
      <path style="fill:#FFFFFF;" d="M92.78,57.886v-0.737H53.733v0.737c0.011,8.321,5.286,15.723,13.148,18.449l-7.643,7.645v1.042
        l7.643,7.644c-7.862,2.726-13.137,10.128-13.148,18.449v0.737H92.78v-0.737c-0.011-8.321-5.286-15.723-13.148-18.449l7.643-7.644
        V83.98l-7.643-7.645C87.494,73.609,92.769,66.207,92.78,57.886z M60.8,84.5l7.683-7.684c1.009,0.253,2.036,0.425,3.072,0.514
        l-6.647,6.65v1.042l6.648,6.648c-1.036,0.089-2.063,0.261-3.072,0.514L60.8,84.5z M66.47,84.5l6.786-6.786l6.786,6.786l-6.785,6.785
        L66.47,84.5z M91.29,110.377H55.222c0.413-9.96,8.822-17.7,18.782-17.287C83.386,93.479,90.902,100.996,91.29,110.377L91.29,110.377
        z M85.712,84.5l-7.683,7.683c-1.009-0.253-2.036-0.425-3.072-0.514l6.648-6.648V83.98l-6.648-6.649
        c1.036-0.089,2.063-0.261,3.072-0.514L85.712,84.5z M73.256,75.935c-9.677-0.011-17.628-7.643-18.034-17.312h36.069
        C90.885,68.292,82.933,75.924,73.256,75.935z"/>
      <rect x="100.851" y="58.874" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.6134 92.2294)" style="fill:#FFFFFF;" width="7.347" height="7.347"/>
      <rect x="100.85" y="74.761" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.8471 96.8824)" style="fill:#FFFFFF;" width="7.347" height="7.347"/>
      <rect x="38.316" y="102.784" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -62.9785 60.8721)" style="fill:#FFFFFF;" width="7.347" height="7.347"/>
      <polygon style="fill:#FFFFFF;" points="22.862,53.283 73.25,23.745 124.296,52.911 129.847,49.705 73.256,17.031 16.665,49.705 "/>
      <path style="fill:#FFFFFF;" d="M28.79,58.975l-5.572-3.216v15.722h-1.965V54.624l-5.571-3.217v15.019H4.959l17.277,26.121
        l17.276-26.121H28.79V58.975z M30.109,71.481l-7.873,11.9l-7.873-11.9H30.109z"/>
      <polygon style="fill:#FFFFFF;" points="125.259,54.624 125.259,64.562 125.23,64.562 130.83,73.029 130.83,51.407 "/>
      <polygon style="fill:#FFFFFF;" points="122.325,54.048 73.263,26.016 24.82,54.414 29.772,57.273 73.256,32.168 116.74,57.273 "/>
      <polygon style="fill:#FFFFFF;" points="123.294,64.562 123.294,55.759 117.723,58.975 117.723,73.028 123.322,64.562 "/>
      <path style="fill:#6C82E9;" d="M73.256,168.884l-73.08-42.191V42.307l73.08-42.191l73.079,42.191v84.386L73.256,168.884z
        M2.517,125.342l70.739,40.84L144,125.342V43.658L73.256,2.818L2.517,43.658V125.342z"/>
      <polygon style="fill:#FFFFFF;" points="123.65,115.717 73.262,145.255 22.217,116.09 16.665,119.295 73.256,151.969 
        129.847,119.295 "/>
      <path style="fill:#FFFFFF;" d="M117.723,110.025l5.571,3.217V97.519h1.965v16.857l5.571,3.217v-15.018h10.723l-17.277-26.121
        L107,102.575h10.723V110.025z M116.4,97.519l7.873-11.9l7.872,11.9H116.4z"/>
      <polygon style="fill:#FFFFFF;" points="21.253,114.376 21.253,104.438 21.282,104.438 15.682,95.971 15.682,117.593 "/>
      <polygon style="fill:#FFFFFF;" points="24.187,114.952 73.249,142.984 121.692,114.587 116.74,111.727 73.256,136.833 
        29.772,111.727 "/>
      <polygon style="fill:#FFFFFF;" points="23.218,104.438 23.218,113.242 28.79,110.025 28.79,95.972 23.19,104.438 "/>
    </g>
  </svg>
</template>
