import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue'
// import LeaderboardPage from './components/LeaderboardPage.vue'
// import AboutPage from './components/AboutPage.vue'
import PatreonPage from './components/PatreonPage.vue'
import TestPage from './components/TestPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'Home',
      path: '/:memberships(.*)*',
      component: Home
    },
    /* {
      name: 'LeaderboardPage',
      path: '/leaderboard',
      component: LeaderboardPage
    }, */
    /* {
      name: 'AboutPage',
      path: '/about',
      component: AboutPage
    }, */
    {
      name: 'PatreonPage',
      path: '/patreon',
      component: PatreonPage
    },
    {
      name: 'TestPage',
      path: '/test',
      component: TestPage
    },
  ]
})

export default router
