<script setup lang="ts">
import { GroupV2 } from 'quria'
import { type AccountResult, PlatformIcon, timeSince } from '@wastedondestiny/destiny-library'
import { computed } from 'vue'

const props = defineProps<{accounts: AccountResult[], clan?: GroupV2}>()
const emit = defineEmits(['select', 'selectClan', 'selectAll', 'cancel'])

const moreThanOneAccount = computed(() => props.accounts.length > 1)

function close() {
  if (props.accounts.length || props.clan) {
    emit('cancel')
  }
}

function select(account: AccountResult) {
  emit('select', account)
}

function selectClan(clan: GroupV2) {
  emit('selectClan', {
    name: clan.name,
    id: clan.groupId
  })
}

function selectAll() {
  emit('selectAll', props.accounts)
}
</script>

<template>
  <div v-if="accounts.length || clan" class="rounded-b-xl shadow-lg shadow-black/50 w-60 md:w-80 absolute top-10 mt-2 z-20" @click.self="close">
    <div v-if="clan" @click="() => selectClan(clan!)"
      class="flex justify-between items-center py-1 px-2 bg-white hover:bg-zinc-100 cursor-pointer"
      :class="{ 'rounded-b-xl': !accounts.length }">
      <span class="flex flex-col">{{ clan.name }} <small class="text-xs">CLAN | {{ clan.memberCount }} Members</small></span>
    </div>
    <div v-for="(account, i) in accounts" :key="i" @click="() => select(account)"
      class="flex justify-between items-center py-1 px-2 bg-white hover:bg-zinc-100 cursor-pointer"
      :class="{ 'rounded-b-xl': !moreThanOneAccount && i === accounts.length - 1 }">
      <span class="flex flex-col">
        <span>
          {{ account.displayName }}
          <small class="text-xs text-gray-500" v-if="account.crossSaveOverride === 99999"> DESTINY 1</small>
        </span>
        <small class="text-xs" v-if="account.dateLastPlayed">Last played {{ timeSince(account.dateLastPlayed) }}</small>
      </span>
      <PlatformIcon :account="account" large />
    </div>
    <div v-if="moreThanOneAccount" class="flex justify-center items-center py-1 px-2 bg-white hover:bg-zinc-100 cursor-pointer rounded-b-xl" @click="selectAll">
      <span>Select all accounts</span>
    </div>
  </div>
</template>
