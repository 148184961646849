<script setup lang="ts">
import { Quria } from 'quria'
import { onMounted, ref } from 'vue'
import { useKonamiCode } from '@wastedondestiny/destiny-library'
import ProjectMenu from './components/ProjectMenu.vue'

const quria = new Quria({
  API_KEY: import.meta.env.VITE_BUNGIE_API_KEY
})
const criticalStatuses = [
  'Destiny2',
  'D2Vendors',
  'D2PublicMilestones',
  'D2MilestoneContent',
  'D2Milestones',
  'D2Characters',
  'D2Profiles',
  'D2Items',
  'D2ReadActions',
  'D2SubmitReport',
  'ScheduledFireteams',
  'DestinyClanSearch',
  'DestinyLinkedProfiles',
  'D2Rewards',
  'ClanFireteams',
  'DestinyClans',
  'Clans',
  'Profiles',
  'Careers',
  'Content',
  'Activities',
  'Messages'
]
const transferStatuses = [
  'D2EquipItem',
  'D2TransferItem',
  'D2PullFromPostmaster',
  'D2SetItemLockState',
  'D2InsertPlugsFree',
  'ClaimSeasonPassReward'
]
const authStatuses = [
  'AccountCreation',
  'SteamIdAuth',
  'EpicIdAuth',
  'PSNAuth',
  'XuidAuth'
]
const statusMessage = ref<string>()
const info = 'For further information, please check @BungieHelp or @Destiny2Team on Twitter/X.'

const { onKonamiCode } = useKonamiCode()
const fun = ref(false)

onKonamiCode(() => {
  fun.value = !fun.value
  console.log('%cFUN MODE ACTIVATED', 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)')
})

onMounted(async () => {
  try {
    const statusResponse = await quria.core.GetCommonSettings()
    const disabledSystems = Object.entries(statusResponse.Response.systems).filter(([, value]) => !value.enabled)
    
    if (disabledSystems.some(([key]) => criticalStatuses.includes(key))) {
      statusMessage.value = 'The API seems to be down. ' + info
    } else if (disabledSystems.some(([key]) => transferStatuses.includes(key))) {
      statusMessage.value = 'The API seems to be partially down, but some features may still work. ' + info
    } else if (disabledSystems.some(([key]) => authStatuses.includes(key))) {
      statusMessage.value = 'The API seems mostly ok, but you may have issues signing in. ' + info
    }
  } catch (_) {
    statusMessage.value = 'The API seems to be down. ' + info
  }
})
</script>

<template>
  <div class="h-full" id="top" :class="{ fun }">
    <ProjectMenu />
    <a v-if="statusMessage" href="https://x.com/BungieHelp" target="_blank" class="w-full block no-underline text-center bg-red-500 opacity-75 py-1 hover:opacity-100">
      {{ statusMessage }}
    </a>
    <RouterView :key="$route.name ?? ''" />
  </div>
</template>
