<script setup lang="ts">
import { ref } from 'vue'

const term = ref('')
const results = ref([])

function search() {
  const username = term.value
  term.value = ''
}
</script>

<template>
  <div class="flex gap-2 justify-center items-center absolute inset-0">
    <div class="bg-zinc-400 flex gap-2 p-10">
      <input v-model="term" class="bg-white p-2">
      <button @click="search" class="bg-white p-2">SEARCH</button>
    </div>
    <div v-for="result in results">
      <span>{{ result }}</span>
    </div>
  </div>
</template>
