<script setup lang="ts">
import { DestinyActivityModeType } from 'quria'
import { Ref, computed, inject } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'
import { type Activity, type Account, type Supporter, TheFooter } from '@wastedondestiny/destiny-library'
import AccountCard from './AccountCard.vue'
import Leaderboard from './Leaderboard.vue'

const route = useRoute()

function getRandomDate(before: boolean = false) {
  const randomDay = Math.floor(Math.random() * 20)
  const date = new Date()
  date.setDate(date.getDate() - randomDay - (before ? 100 : 0))
  return date
}

const activities: Activity[] = [
  {
    id: '123',
    modes: [DestinyActivityModeType.Patrol],
    period: getRandomDate(true),
    timePlayed: 295400
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Strike],
    period: getRandomDate(true),
    timePlayed: 245400
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Raid],
    period: getRandomDate(true),
    timePlayed: 105400
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Gambit],
    period: getRandomDate(true),
    timePlayed: 85400
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Control],
    period: getRandomDate(true),
    timePlayed: 105400
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Patrol],
    period: getRandomDate(),
    timePlayed: 9540
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Patrol],
    period: getRandomDate(),
    timePlayed: 8340
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Story],
    period: getRandomDate(),
    timePlayed: 3450
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Strike],
    period: getRandomDate(),
    timePlayed: 2450
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Raid],
    period: getRandomDate(),
    timePlayed: 14554
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Gambit],
    period: getRandomDate(),
    timePlayed: 4532
  },
  {
    id: '123',
    modes: [DestinyActivityModeType.Control],
    period: getRandomDate(),
    timePlayed: 4565
  }
]
const demoAccount: Account = {
  characters: [{
    characterId: '',
    deleted: false,
    timePlayed: activities.reduce((a, x) => a + x.timePlayed, 0),
    timeAfk: 34532,
    class: 0,
    emblem: 4132147344,
    gender: 0,
    lastPlayed: new Date,
    level: 1600,
    race: 0
  }],
  displayName: 'Example#1234',
  fireteam: [],
  membershipId: 'test1',
  membershipType: 2,
  crossSaveOverride: 3,
  guardianRank: {
    previous: 8,
    current: 6,
    highest: 8
  },
  clan: {
    name: 'Cool clan',
    id: '123'
  },
  d1TotalTime: 0,
  d1DeletedTime: 0,
  activeTriumph: 2545,
  totalTriumph: 10502,
  titles: [
    {
      id: '1',
      name: 'Example',
      isLegacy: true
    },
    {
      id: '2',
      name: 'Example',
      isLegacy: false
    },
    {
      id: '3',
      name: 'Example',
      isLegacy: false
    }
  ],
  lastActivity: 'Wellspring: Attack',
  seasons: [
    {
      number: 8,
      season: 'Undying',
      seasonRank: 34,
      seasonPrestige: 0,
      startDate: new Date()
    },
    {
      number: 9,
      season: 'Dawn',
      seasonRank: 45,
      seasonPrestige: 0,
      startDate: new Date()
    },
    {
      number: 10,
      season: 'Worthy',
      seasonRank: 23,
      seasonPrestige: 0,
      startDate: new Date()
    },
    {
      number: 11,
      season: 'Arrivals',
      seasonRank: 89,
      seasonPrestige: 0,
      startDate: new Date()
    }
  ],
  private: false
}
const dummyAccount: Account = {
  characters: [],
  displayName: 'Example#1234',
  fireteam: [],
  membershipId: 'test2',
  membershipType: 2,
  guardianRank: {
    previous: 8,
    current: 6,
    highest: 8
  },
  clan: {
    name: 'Cool clan',
    id: '123'
  },
  d1TotalTime: 0,
  d1DeletedTime: 0,
  crossSaveOverride: 1,
  activeTriumph: 0,
  totalTriumph: 0,
  titles: [],
  lastActivity: '',
  seasons: [],
  private: false
}
const leaderboard: LeaderboardPage = {
  count: 10,
  page: 1,
  players: [
    {
      displayName: '4204nx10usF4bul0us#4563',
      membershipId: '123',
      membershipType: 2,
      value: 867355
    },
    {
      displayName: 'Fl00rOny3k4chukw69#4523',
      membershipId: '123',
      membershipType: 5,
      value: 756766
    },
    {
      displayName: 'RubidiumToad#0341',
      membershipId: '123',
      membershipType: 1,
      value: 573443
    },
    {
      displayName: 'C4ul1fl0w3rM4nd4r1n#8344',
      membershipId: '123',
      membershipType: 3,
      value: 456766
    },
    {
      displayName: 'Ghost the Famous#1422',
      membershipId: '123',
      membershipType: 1,
      value: 46766
    }
  ],
  total: 345763
}
const supporters = inject<Ref<Supporter[]>>('supporters')
const supporterLinks = computed(() => supporters?.value?.reduce((all, supporter) => {
  if (supporter.linkedMembershipIds) {
    all[supporter.name] = {
      name: 'Home',
      params: {
        memberships: (route.params.memberships as string[] || []).filter(x => !supporter.linkedMembershipIds?.includes(x)).concat(supporter.linkedMembershipIds)
      }
    }
  }

  return all
}, {} as Record<string, RouteLocationRaw>))
</script>

<template>
  <div class="w-full md:w-5/6 lg:w-4/5 px-2 py-4 text-zinc-200 mx-auto">
    <!-- <RouterLink to="/" class="flex items-center text-lg"><img src="../assets/caret.svg" class="h-6 w-6 invert rotate-90" />Go back</RouterLink> -->
    <h1 id="about" class="text-center text-4xl my-2 r-zinc-200 border-b pb-2">About Time Wasted on Destiny</h1>
    <div class="grid about-grid gap-x-8">
      <div class="about-grid-a text-zinc-200">
        <p class="indent-4 text-justify">
          Time Wasted on Destiny is a website designed to help you track your gameplay statistics for Destiny 2,
          allowing you to make informed decisions when
          forming a fireteam or planning activities. Often, players may not remember specific gameplay details, and
          that's where Time Wasted on Destiny comes in.
          With Time Wasted on Destiny, you can just enter their name to access their time played and seasonal ranks,
          ensuring a smoother experience.
        </p>
        <h2 class="text-2xl my-2 font-semibold">How to use</h2>
        <p class="indent-4 text-justify">
          Type your or a friend's Bungie name (with or without the #1234 numbers) into the search bar above to add them
          to the list. From there,
          you can easily compare time played, activity breakdown and seasonal ranks. If you want to remove a player from
          the list, just click the close button
          <img src="../assets/close.svg" class="inline align-middle pb-1 h-5 w-4 invert" /> at the top right of their
          card. You can also add
          a user's current fireteam to the list by clicking the Add Fireteam button, if it's available.
        </p>
        <p class="mt-2 indent-4 text-justify">
          Time played, seasonal ranks, and activity history provide valuable insights into a player's Destiny 2 journey.
          Triumph score, titles obtained, and
          time played or wasted on deleted characters can also be compared with other players using the leaderboard,
          allowing for friendly competition between
          peers. You may also switch from global leaderboard to clan leaderboard and compare with other members of your
          clan.
        </p>
      </div>
      <div class="about-grid-b">
        <h2 class="text-2xl my-2 font-semibold">Demonstration</h2>
        <AccountCard class="mx-auto text-black" :account="demoAccount" :accounts="[demoAccount, dummyAccount]"
          activities-loaded :activities="activities"
          :is-open="{ detail: true, seasons: true, activities: true, characters: true }" :loading="false" is-demo />
      </div>
      <div class="about-grid-c">
        <Leaderboard class="h-[24.5rem] sm:h-[17rem]" :page="leaderboard" sorting="timePlayed" is-demo />
      </div>
    </div>
    <div class="text-zinc-200 text-justify">
      <h2 class="text-2xl my-2 font-semibold">Disclaimer</h2>
      <p class="indent-4">
        Time Wasted on Destiny is not affiliated with <a href="https://www.bungie.net" target="_blank"
          class="opacity-75 hover:opacity-100">Bungie</a>. Destiny
        is a registered trademark of Bungie.
      </p>
      <h2 class="text-2xl my-2 font-semibold">Supporters</h2>
      <p class="indent-4">
        Time Wasted on Destiny could not survive without the support of Guardians like you. If you like seeing tools
        like Time Wasted on Destiny,
        <a href="https://crimson.report" target="_blank" class="opacity-75 hover:opacity-100">crimson.report</a> or
        <a href="https://loot.report" target="_blank" class="opacity-75 hover:opacity-100">loot.report</a> exist, please
        consider <a href="http://www.patreon.com/wastedondestiny" target="_blank"
          class="opacity-75 hover:opacity-100">supporting me on Patreon</a>.
        Here are the top supporters:
      </p>
      <ul class="text-left list-disc ml-6">
        <li v-for="supporter in supporters">
          <RouterLink v-if="supporterLinks?.[supporter.name]" :to="supporterLinks[supporter.name]">{{ supporter.name }}
            ({{ supporter.tier }})</RouterLink>
          <span v-else>{{ supporter.name }} ({{ supporter.tier }})</span>
        </li>
      </ul>
      <h2 class="text-2xl my-2 font-semibold">Credits</h2>
      <p class="indent-4">
        Time Wasted on Destiny is made possible thanks to:
      </p>
      <ul class="text-left list-disc ml-6 mb-10">
        <li><a href="https://www.bungie.com" target="_blank" class="opacity-75 hover:opacity-100">Bungie</a>, who is
          giving us the best game API out there</li>
        <li>All my friends and supporters for helping me crushing bugs and test the website</li>
      </ul>
    </div>
    <TheFooter about name="Time Wasted on Destiny">
      <a href="https://binar.ca" target="_blank">&copy; François (binarmorker) Allard {{ (new Date()).getFullYear() }}</a>
    </TheFooter>
  </div>
</template>

<style scoped lang="postcss">
.about-grid {
  grid-template-areas: 'a a a a'
    'a a a a'
    'b b b b'
    'c c c c';

  @media screen(lg) {
    grid-template-areas: 'a a a b'
      'a a a b'
      'a a a b'
      'c c c b';
  }
}

.about-grid-a {
  grid-area: a;
}

.about-grid-b {
  grid-area: b;
}

.about-grid-c {
  grid-area: c;
}
</style>
