<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { type Supporter, PlatformIcon, TheTooltip, formatNumber, formatTime, useMediaQuery } from '@wastedondestiny/destiny-library'
import { useRoute } from 'vue-router'

const route = useRoute()
const isNotMobile = useMediaQuery('(min-width: 640px)')
const props = defineProps<{page?: LeaderboardPage, clan?: Clan, sorting: LeaderboardSorting, loading?: boolean, isDemo?: boolean}>()
const emit = defineEmits(['onPageChange', 'onSortingChange', 'removeClan'])
const supporters = inject<Supporter[]>('supporters')

const maxPage = 10
const _totalPlayers = ref(0)
const totalPlayers = computed(() => {
  if (props.page?.total && props.page.total !== +_totalPlayers.value) {
    _totalPlayers.value = +props.page.total
  }

  return formatNumber(_totalPlayers.value)
})

function firstPage() {
  emit('onPageChange', 1)
}

function previousPage() {
  emit('onPageChange', (props.page?.page || 2) - 1)
}

function nextPage() {
  emit('onPageChange', (props.page?.page || 1) + 1)
}

function sortBy(sorting: LeaderboardSorting) {
  if (sorting === props.sorting) return

  emit('onSortingChange', sorting)
}

function removeClan() {
  emit('removeClan')
}

function getLoadLink(player: LeaderboardPlayer) {
  return {
    name: 'Home',
    params: {
      memberships: (route.params.memberships as string[] || []).filter(x => x !== player.membershipId).concat([ player.membershipId ])
    }
  }
}
</script>

<template>
  <div class="w-full bg-white relative rounded-2xl">
    <ul>
      <li v-if="page && !loading" class="flex flex-col-reverse sm:flex-row items-center text-black justify-between py-1 mx-2 border-b-zinc-200 border-b -mb-[1px]">
        <div v-if="clan" class="h-12 flex gap-x-2 flex-wrap sm:flex-nowrap sm:flex-col justify-center">
          <span class="flex sm:h-12 items-center">
            Clan:
            <div class="ml-1 h-6 flex justify-between px-1 bg-zinc-300 rounded-md">
              {{ clan.name }}
              <span @click="removeClan" class="cursor-pointer opacity-75 hover:opacity-100 ml-2 inline-flex items-center justify-center">
                <img alt="Close button" src="../assets/close.svg" class="h-4 w-4" />
              </span>
            </div>
          </span>
          <span class="sm:h-12 flex flex-col justify-center">Members: {{ totalPlayers }}</span>
        </div>
        <div v-else class="h-12 flex flex-col justify-center">
          <span>Total players: {{ totalPlayers }}</span>
        </div>
        <div class="flex items-center gap-1 h-10 sm:rounded-tr-xl overflow-hidden">
          <span class="leading-4 text-zinc-400 text-sm">Filter by:</span>
          <button class="w-16 uppercase text-xs p-1" :class="{ 'cursor-default': isDemo || sorting === 'timePlayed', 'text-black bg-zinc-300': sorting === 'timePlayed', 'bg-zinc-800 text-white': sorting !== 'timePlayed', 'hover:bg-zinc-600 cursor-pointer': sorting !== 'timePlayed' && !isDemo }" @click="() => sortBy('timePlayed')">Time played</button>
          <button class="w-16 uppercase text-xs p-1" :class="{ 'cursor-default': isDemo || sorting === 'timeWasted', 'text-black bg-zinc-300': sorting === 'timeWasted', 'bg-zinc-800 text-white': sorting !== 'timeWasted', 'hover:bg-zinc-600 cursor-pointer': sorting !== 'timeWasted' && !isDemo }" @click="() => sortBy('timeWasted')">Time wasted</button>
          <button class="w-16 uppercase text-xs p-1" :class="{ 'cursor-default': isDemo || sorting === 'triumph', 'text-black bg-zinc-300': sorting === 'triumph', 'bg-zinc-800 text-white': sorting !== 'triumph', 'hover:bg-zinc-600 cursor-pointer': sorting !== 'triumph' && !isDemo }" @click="() => sortBy('triumph')">Triumph score</button>
          <button class="w-16 uppercase text-xs p-1" :class="{ 'cursor-default': isDemo || sorting === 'seals', 'text-black bg-zinc-300': sorting === 'seals', 'bg-zinc-800 text-white': sorting !== 'seals', 'hover:bg-zinc-600 cursor-pointer': sorting !== 'seals' && !isDemo }" @click="() => sortBy('seals')">Titles obtained</button>
        </div>
      </li>
      <li v-if="page && !loading" v-for="(player, i) in page.players" :key="player.membershipId"
        class="flex flex-col sm:flex-row text-black justify-between sm:items-center py-1 mx-2 h-12 leading-4 sm:leading-normal sm:h-8 border-b-zinc-200 border-b">
        <span class="flex gap-2 items-center">
          <small class="w-8 text-xs text-zinc-400">{{ 1 + i + (10 * (page.page - 1)) }}</small>
          <PlatformIcon :account="player" :supporting="supporters?.find(x => x.linkedMembershipIds?.includes(player.membershipId))" :no-interact="isDemo" />
          <span v-if="isDemo">{{ player.displayName }}</span>
          <RouterLink v-else class="no-underline hover:underline" :to="getLoadLink(player)">{{ player.displayName }}</RouterLink>
        </span>
        <span class="font-semibold self-end tabular-nums">{{ sorting === 'timePlayed' || sorting === 'timeWasted' ? formatTime(player.value) : formatNumber(player.value) }}</span>
      </li>
      <li v-if="loading">
        <div class="flex flex-col gap-2 justify-center items-center h-[39.5rem] sm:h-[27rem]">
          <div class="text-3xl leading-7 font-serif font-bold flex gap-2">
            <span class="transition-opacity bullet-1">&bullet;</span>
            <span class="transition-opacity bullet-2">&bullet;</span>
            <span class="transition-opacity bullet-3">&bullet;</span>
          </div>
          <span class="text-black/50 text-xs">LOADING</span>
        </div>
      </li>
      <li v-if="page" class="flex justify-between items-center p-2 text-sm h-14 absolute bottom-0 left-0 right-0">
        <div v-if="page.page > 1" class="flex gap-2 w-[6.5rem] sm:w-52 rounded-bl-xl overflow-hidden">
          <button @click="firstPage" class="p-2 bg-zinc-800 w-12 sm:w-28 h-10" :class="{ 'cursor-default': isDemo, 'hover:bg-zinc-600': !isDemo }">⇤<span class="hidden sm:inline"> FIRST</span></button>
          <button @click="previousPage" class="p-2 bg-zinc-800 w-12 sm:w-28 h-10" :class="{ 'cursor-default': isDemo, 'hover:bg-zinc-600': !isDemo }">←<span class="hidden sm:inline"> PREVIOUS</span></button>
        </div>
        <span v-else class="w-[6.5rem] sm:w-52" />
        <small class="text-zinc-400 text-xs">{{ formatNumber(page.page) }} / {{ formatNumber(Math.ceil(page.total / 10)) }}</small>
        <div v-if="10 * (page.page) < page.total && page.page < maxPage" class="flex justify-end w-[6.5rem] sm:w-52 rounded-br-xl overflow-hidden">
          <button @click="nextPage" class="p-2 bg-zinc-800 w-12 sm:w-28 h-10" :class="{ 'cursor-default': isDemo, 'hover:bg-zinc-600': !isDemo }"><span class="hidden sm:inline">NEXT </span>→</button>
        </div>
        <span v-else class="w-[6.5rem] sm:w-52" />
      </li>
    </ul>
    <template v-if="isDemo && isNotMobile">
      <TheTooltip class="absolute" style="top: 12px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 32px; width: 160px;" />
        <template #content>
          <p class="w-52">This is the total number of guardians in this leaderboard. It is by no means an accurate count of all Destiny 2 players. If your name has never been searched on this website, it will not be a part of this leaderboard.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 2px; right: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 52px; width: 280px;" />
        <template #content>
          <p class="w-52">The filters are clickable, and allow you to choose which metric to compare players against. Take into consideration that changing the filter will return the leaderboard to the first page.</p>
        </template>
      </TheTooltip>
      <TheTooltip class="absolute" style="top: 88px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 32px; width: 280px;" />
        <template #content>
          <p class="w-52">The name of a player is clickable, allowing you to load their information in the main section like when you enter their username in the search bar.</p>
        </template>
      </TheTooltip>
    </template>
    <template v-if="isDemo && !isNotMobile">
      <div class="w-full absolute flex flex-col items-center" style="top: 0;">
        <TheTooltip style="margin-top: -2px;">
          <div class="border-blue-500 border-2 rounded-md" style="height: 52px; width: 342px;" />
          <template #content>
            <p class="w-52">The filters are clickable, and allow you to choose which metric to compare players against. Take into consideration that changing the filter will return the leaderboard to the first page.</p>
          </template>
        </TheTooltip>
        <TheTooltip style="margin-top: 2px;">
          <div class="border-blue-500 border-2 rounded-md" style="height: 32px; width: 160px;" />
          <template #content>
            <p class="w-52">This is the total number of guardians in this leaderboard. It is by no means an accurate count of all Destiny 2 players. If your name has never been searched on this website, it will not be a part of this leaderboard.</p>
          </template>
        </TheTooltip>
      </div>
      <TheTooltip class="absolute" style="top: 142px; left: 2px;">
        <div class="border-blue-500 border-2 rounded-md" style="height: 32px; width: 280px;" />
        <template #content>
          <p class="w-52">The name of a player is clickable, allowing you to load their information in the main section like when you enter their username in the search bar.</p>
        </template>
      </TheTooltip>
    </template>
  </div>
</template>
