<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'

const props = defineProps<{points: number[], width: number, height: number}>()
const lineWidth = 2
const zoom = 2

const maximum = computed(() => props.points.reduce((acc, x) => acc > x ? acc : x))
const canvas = ref<HTMLCanvasElement>()

function display() {
  const context = canvas.value?.getContext('2d')
  const length = props.points.length - 1

  if (canvas.value && context) {
    const height = canvas.value.height
    const width = canvas.value.width
    const padding = (8 + lineWidth) * zoom
    
    context.clearRect(0, 0, width, height)
    context.beginPath()
    
    const gradient = context.createLinearGradient(padding, height, width - padding, height)
    gradient.addColorStop(0, 'rgb(255, 255, 255)')
    gradient.addColorStop(1, 'rgb(59, 130, 246)')

    context.lineWidth = lineWidth * zoom
    context.lineCap = 'round'
    context.lineJoin = 'round'
    context.strokeStyle = gradient

    props.points.forEach((point, i) => {
      const lastY = (height - padding) - ((point / maximum.value) * (height - (2 * padding)))
      const lastX = padding + ((width - (2 * padding)) / length) * i
      
      if (i === 0) context.moveTo(lastX, lastY)

      context.lineTo(lastX, lastY)
    })

    context.stroke()
  }
}

onMounted(display)
watch(() => props, display)
</script>

<template>
  <canvas ref="canvas" :width="width * zoom" :height="height * zoom" />
</template>
