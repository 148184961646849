<script setup lang="ts">
import { Ref, computed, inject, onMounted, ref, watch } from 'vue'
import { DestinyComponentType, DestinyRecordComponent, DestinyRecordState, Quria } from 'quria'
import Leaderboard from './Leaderboard.vue'
import titleDefinitions from '../generated/titleDefinitions.json'
import { formatUsername } from '@wastedondestiny/destiny-library'

const loading = ref(true)
const page = ref(1)
const sorting = ref<LeaderboardSorting>('timePlayed')
const pages = ref(new Map<string, LeaderboardPage>())
const clanPages = ref(new Map<string, LeaderboardPage>())
const selectedClan = inject('selectedClan') as Ref<Clan | undefined>
const quria = new Quria({
  API_KEY: import.meta.env.VITE_BUNGIE_API_KEY
})

const promiseQueue: (() => Promise<Response>)[] = []

const currentPage = computed(() => {
  const key = `${sorting.value}_${page.value}`

  if (selectedClan.value) return clanPages.value.has(key) ? clanPages.value.get(key) : undefined

  return pages.value.has(key) ? pages.value.get(key) : undefined
})

async function loadPage(pageNumber: number) {
  loading.value = true
  const key = `${sorting.value}_${pageNumber}`
  const hasLoadedPage = pages.value.has(key)
  
  if (page.value === pageNumber && hasLoadedPage) {
    loading.value = false
    return
  }

  page.value = pageNumber

  if (hasLoadedPage) {
    loading.value = false
    return
  }

  const leaderboardRequest = await fetch(`${import.meta.env.VITE_API_URL}/leaderboard/${pageNumber}/?sorting=${sorting.value}`)
  const leaderboardResponse = await leaderboardRequest.json()
  pages.value.set(key, leaderboardResponse as LeaderboardPage)
  loading.value = false
}

async function loadClanPage(pageNumber: number) {
  loading.value = true
  const key = `${sorting.value}_${pageNumber}`
  const hasLoadedPage = clanPages.value.has(key)
  
  if (page.value === pageNumber && hasLoadedPage) {
    loading.value = false
    return
  }

  page.value = pageNumber
  loading.value = false
}

async function changeSorting(newSorting: LeaderboardSorting) {
  sorting.value = newSorting
  selectedClan.value ? await loadClanPage(1) : await loadPage(1)
}

async function changePage(page: number) {
  selectedClan.value ? await loadClanPage(page) : await loadPage(page)
}

function getTitles(records: Record<string, DestinyRecordComponent>) {
  if (!records) return []
  return titleDefinitions.filter(x => ((records[x.id]?.state ?? 0) & DestinyRecordState.CanEquipTitle) === DestinyRecordState.CanEquipTitle)
}

async function loadClanMembers(groupId: string, pageNumber: number = 1) {
  const clanMembers = await quria.groupv2.GetMembersOfGroup(pageNumber, groupId, {})

  let memberList: Promise<LeaderboardClanPlayer>[] = clanMembers.Response.results.map(x => new Promise(async resolve => {
    // Don't wait for it
    promiseQueue.push(() => fetch(`${import.meta.env.VITE_API_URL}/profile/${x.destinyUserInfo.membershipId}/`))
    
    const profile = await quria.destiny2.GetProfile(x.destinyUserInfo.membershipId, x.destinyUserInfo.membershipType, {
      components: [ DestinyComponentType.Records ]
    })
    const stats = await quria.destiny2.GetHistoricalStatsForAccount(x.destinyUserInfo.membershipId, x.destinyUserInfo.membershipType, {})
    const characters = stats.Response?.characters?.map(y => ({
      timePlayed: y.merged?.allTime?.['secondsPlayed']?.basic?.value || 0,
      isDeleted: y.deleted
    })) ?? []
    const timePlayed = characters.reduce((a, y) => a + y.timePlayed, 0)
    const timeWasted = characters.filter(y => y.isDeleted).reduce((a, y) => a + y.timePlayed, 0)
    const triumph = profile.Response?.profileRecords?.data?.lifetimeScore || 0
    const seals = profile.Response?.profileRecords?.data ? getTitles(profile.Response.profileRecords.data.records).length : 0
    resolve({
      membershipId: x.destinyUserInfo.membershipId,
      membershipType: x.destinyUserInfo.membershipType,
      displayName: formatUsername(x.destinyUserInfo.bungieGlobalDisplayName, x.destinyUserInfo.bungieGlobalDisplayNameCode || 0, x.destinyUserInfo.LastSeenDisplayName),
      timePlayed,
      timeWasted,
      triumph,
      seals
    })
  }))

  if (clanMembers.Response?.hasMore) {
    const moreClanMembers = await loadClanMembers(groupId, pageNumber + 1)
    memberList = [...memberList, ...moreClanMembers]
  }

  return memberList
}

function removeClan() {
  if (selectedClan.value) selectedClan.value = undefined
}

async function processClanQueue() {
  let processing: Promise<Response>[] = []

  while (promiseQueue.length) {
    for (let i = 0; i < Math.min(3, promiseQueue.length); i++) {
      const promise = promiseQueue.pop()
      if (promise) processing.push(promise?.())
    }

    await Promise.allSettled(processing)
    processing = []
  }
}

onMounted(async () => {
  await loadPage(page.value)
})
watch(selectedClan, async value => {
  sorting.value = 'timePlayed'

  if (value) {
    loading.value = true
    clanPages.value.clear()
    const memberList = await loadClanMembers(value.id)
    const members = await Promise.all(memberList)

    processClanQueue()
    
    for (const sorting of ['timePlayed', 'timeWasted', 'triumph', 'seals']) {
      const sortedMembers = members.sort((a, b) => b[sorting as LeaderboardSorting] - a[sorting as LeaderboardSorting])

      for (let i = 0; i < sortedMembers.length / 10; i++) {
        const page = i + 1
        const chunk = sortedMembers.slice(i * 10, page * 10)

        clanPages.value.set(`${sorting}_${page}`, {
          count: chunk.length,
          page: page,
          players: chunk.map(x => ({
            displayName: x.displayName,
            membershipId: x.membershipId,
            membershipType: x.membershipType,
            value: x[sorting as LeaderboardSorting]
          })),
          total: members.length
        })
      }
    }

    await loadClanPage(1)
  } else {
    await loadPage(1)
  }
})
</script>

<template>
  <div class="-z-10 w-full px-2 py-4 max-w-[48rem] text-zinc-200 mx-auto">
    <h1 id="leaderboard" class="text-center text-4xl my-2 border-zinc-200 border-b pb-2">Leaderboard</h1>
    <Leaderboard class="h-[39.5rem] sm:h-[27rem]" :loading="loading" :clan="selectedClan" :page="currentPage" :sorting="sorting" @remove-clan="removeClan" @on-page-change="changePage" @on-sorting-change="changeSorting" />
  </div>
</template>
