<script setup lang="ts">
import { Quria, BungieMembershipType, GroupType, GroupV2, APIResponse, UserInfoCard, DestinyProfileUserInfoCard } from 'quria'
import { ref, onMounted, onBeforeUnmount, inject, Ref } from 'vue'
import { type AccountResult, formatUsername } from '@wastedondestiny/destiny-library'
import AccountPicker from './AccountPicker.vue'

const emit = defineEmits(['search'])

const searchTerm = ref('')
const loading = ref(false)
const accounts = ref([] as AccountResult[])
const clan = ref<GroupV2>()
const searchBar = ref<HTMLInputElement | null>(null)
const quria = new Quria({
  API_KEY: import.meta.env.VITE_BUNGIE_API_KEY
})
// let controller: AbortController|undefined

async function getProfile(membershipType: number, membershipId: string) {
  const profile = await quria.destiny2.GetLinkedProfiles(membershipId, membershipType, { getAllMemberships: true })
  const accountResults: AccountResult[] = []

  for (const userInfo of profile.Response?.profiles || []) {
    if (!accounts.value.find(x => x.membershipId === userInfo.membershipId)) {
      accountResults.push({
        displayName: formatUsername(userInfo.bungieGlobalDisplayName, userInfo.bungieGlobalDisplayNameCode || 0, userInfo.displayName),
        membershipId: userInfo.membershipId,
        crossSaveOverride: userInfo.crossSaveOverride,
        dateLastPlayed: new Date(userInfo.dateLastPlayed),
        membershipType: userInfo.membershipType
      })
    }
  }

  return accountResults
}

async function getLegacyProfile(membershipType: number, membershipId: string, displayName: string) {
  const search = await fetch(`https://www.bungie.net/d1/Platform/Destiny/${membershipType}/Account/${membershipId}/`, {
    headers: {
      'X-API-Key': import.meta.env.VITE_BUNGIE_API_KEY
    },
    // signal: controller?.signal
  })
  const results = await search.json()
  const account = results.Response?.data as DestinyProfileUserInfoCard|null
  
  if (account) {
    return {
      displayName,
      membershipId: account.membershipId,
      crossSaveOverride: 99999,
      dateLastPlayed: new Date(account.dateLastPlayed),
      membershipType: account.membershipType
    }
  }

  return null
}

async function prefixSearch(value: string) {
  const search = await quria.user.SearchByGlobalNamePost(0, value)
  let accountResults: AccountResult[] = []

  for (const results of search.Response?.searchResults) {
    const userInfo = results?.destinyMemberships?.[0]

    if (userInfo) accountResults = [
      ...accountResults,
      ...await getProfile(userInfo.membershipType, userInfo.membershipId)
    ]
  }

  accounts.value = [...accounts.value, ...accountResults]
}

async function exactSearch(value: string) {
  const [displayName, displayNameCode] = value.split('#')
  const search = await quria.destiny2.SearchDestinyPlayerByBungieName(BungieMembershipType.All, displayName, parseInt(displayNameCode, 10))
  const userInfo = search.Response?.[0]
  let accountResults: AccountResult[] = []

  if (userInfo) accountResults = await getProfile(userInfo.membershipType, userInfo.membershipId)

  accounts.value = [...accounts.value, ...accountResults]
}

async function platformSearch(value: string) {
  const search = await fetch(`https://www.bungie.net/d1/Platform/Destiny/SearchDestinyPlayer/-1/${encodeURIComponent(value)}/`, {
    headers: {
      'X-API-Key': import.meta.env.VITE_BUNGIE_API_KEY
    },
    // signal: controller?.signal
  })
  const results = await search.json()
  const userInfos = (results as never as APIResponse<UserInfoCard[]>).Response
  let accountResults: AccountResult[] = []

  for (const userInfo of userInfos) {
    if (userInfo && !accounts.value.find(x => x.membershipId === userInfo.membershipId)) {
      accountResults = [
        ...accountResults,
        ...await getProfile(userInfo.membershipType, userInfo.membershipId)
      ]
      
      const account = await getLegacyProfile(userInfo.membershipType, userInfo.membershipId, userInfo.displayName)

      if (account) accountResults = [
        ...accountResults,
        account
      ]
    }
  }

  accounts.value = [...accounts.value, ...accountResults]
}

async function notFoundSearch(value: string) {
  const search = await fetch(`https://elastic.destinytrialsreport.com/players/0/${value}/`, {
    // signal: controller?.signal
  })
  const results = await search.json()

  for (const result of results) accounts.value.push({
    displayName: result.bungieName || result.displayName,
    membershipId: result.membershipId,
    membershipType: result.membershipType,
    crossSaveOverride: result.crossSaveOverride.membershipType,
    dateLastPlayed: new Date(result.lastPlayed)
  })
}

async function clanSearch(value: string) {
  const search = await quria.groupv2.GetGroupByNameV2(value, GroupType.Clan)
  if (search.Response) clan.value = search.Response.detail
}

async function search() {
  accounts.value = []
  clan.value = undefined
  // controller = new AbortController()

  if (!searchTerm.value) return

  loading.value = true

  if (searchTerm.value.includes('#')) {
    await exactSearch(searchTerm.value)
  } else {
    await prefixSearch(searchTerm.value)
    await platformSearch(searchTerm.value)
  }

  if (!accounts.value.length) {
    await notFoundSearch(searchTerm.value)
  }

  await clanSearch(searchTerm.value)

  loading.value = false
}

function select(account: AccountResult|null) {
  accounts.value = []
  clan.value = undefined
  searchTerm.value = ''

  if (account) emit('search', account)
}

function cancel() {
  select(null)
  // controller?.abort()
}

function selectAll(selectedAccounts: AccountResult[]) {
  accounts.value = []
  clan.value = undefined
  searchTerm.value = ''

  for (const account of selectedAccounts) {
    emit('search', account)
  }
}

const selectedClan = inject('selectedClan') as Ref<Clan>

function selectClan(_clan: Clan) {
  accounts.value = []
  clan.value = undefined
  searchTerm.value = ''
  selectedClan.value = _clan
  scrollTo({
    top: document.getElementById('leaderboard')?.offsetTop,
    behavior: 'smooth'
  })
}

function onEnterPress(event: KeyboardEvent) {
  if (event.key === 'Enter') search()
}

onMounted(() => { searchBar.value?.addEventListener('keypress', onEnterPress) })
onBeforeUnmount(() => { searchBar.value?.removeEventListener('keypress', onEnterPress) })
</script>

<template>
  <div class="flex flex-col items-center relative">
    <div class="relative">
      <input class="py-2 pl-4 pr-28 w-72 md:w-96 h-12 rounded-3xl bg-white outline-none"
        aria-label="Search bar" type="text" ref="searchBar" placeholder="Search a username or a clan…" v-model="searchTerm" />
      <div v-if="loading" class="absolute top-0 right-0 w-24 h-10 p-1 m-1 rounded-3xl bg-blue-500 text-white text-3xl leading-7 font-serif font-bold flex gap-2 justify-center">
        <span class="transition-opacity bullet-1">&bullet;</span>
        <span class="transition-opacity bullet-2">&bullet;</span>
        <span class="transition-opacity bullet-3">&bullet;</span>
      </div>
      <button v-else class="absolute top-0 right-0 w-24 h-10 p-2 m-1 rounded-3xl bg-blue-500 text-white hover:bg-blue-700" @click="search">SEARCH</button>
    </div>
    <AccountPicker :accounts="accounts" :clan="clan" @select="select" @select-clan="selectClan" @select-all="selectAll" @cancel="cancel" />
  </div>
</template>
