<script setup lang="ts">
import { ref } from 'vue'

withDefaults(defineProps<{
  src: string,
  fallbackSrc: string,
  alt?: string
}>(), {
  alt: ''
})

const didLoad = ref(true)

function pictureLoadError() {
  didLoad.value = false
}
</script>

<template>
  <img v-if="didLoad" :src="src" @error="pictureLoadError" :alt="alt" />
  <img v-else :src="fallbackSrc" :alt="alt" />
</template>
