<script setup lang="ts">
import { ref } from 'vue'

const menu = ref<HTMLElement>()

function openMenu() {
  menu.value?.classList.remove('hidden')
  menu.value?.classList.add('flex')
}

function closeMenu() {
  menu.value?.classList.add('hidden')
  menu.value?.classList.remove('flex')
}
</script>

<template>
  <div class="hidden md:flex max-h-12 justify-center items-center bg-zinc-900 p-2 shadow-md text-zinc-100 shadow-black/25 text-shadow-sm">
    <a class="flex items-center gap-2 no-underline bg-blue-500 h-12 px-2">
      <img src="../assets/twod.svg" class="invert w-8 h-8" />
      <div class="flex flex-col">
        <p class="font-mono leading-4">TIME WASTED <span class="text-zinc-600">ON DESTINY</span></p>
        <small class="leading-none">Time spent on Destiny 2</small>
      </div>
    </a>
    <a href="https://crimson.report" target="_blank" class="flex items-center gap-2 no-underline hover:bg-red-500 h-12 px-2">
      <img src="../assets/crimsonreport.svg" class="invert w-8 h-8" />
      <div class="flex flex-col">
        <p class="font-mono leading-4">CRIMSON<span class="text-zinc-600">.REPORT</span></p>
        <small class="leading-none">Content owned on Destiny 2</small>
      </div>
    </a>
    <a href="https://loot.report" target="_blank" class="flex items-center gap-2 no-underline hover:bg-yellow-500 h-12 px-2">
      <img src="../assets/lootreport.svg" class="invert w-8 h-8" />
      <div class="flex flex-col">
        <p class="font-mono leading-4">LOOT<span class="text-zinc-600">.REPORT</span></p>
        <small class="leading-none">Exotics acquired on Destiny 2</small>
      </div>
    </a>
  </div>
  <button class="absolute md:hidden" @click="openMenu">
    <svg class="block h-12 w-12 p-2 ml-2 mt-2 fill-zinc-200" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <title>Mobile menu</title>
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
    </svg>
  </button>
  <div class="hidden md:hidden" ref="menu">
    <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" @click="closeMenu" />
    <div class="fixed left-0 top-0 bottom-0 z-50 flex-col bg-zinc-900 py-2 shadow-md text-zinc-100 shadow-black/25 text-shadow-sm">
      <button @click="closeMenu">
        <svg class="block h-12 w-12 p-2 ml-2 mb-2 fill-zinc-200" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Close menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>
      <a target="_blank" class="flex items-center gap-2 no-underline bg-blue-500 h-12 px-2">
        <img src="../assets/twod.svg" class="invert w-8 h-8" />
        <div class="flex flex-col">
          <p class="font-mono leading-4">TIME WASTED <span class="text-zinc-600">ON DESTINY</span></p>
          <small class="leading-none">Time spent on Destiny 2</small>
        </div>
      </a>
      <a href="https://crimson.report" target="_blank" class="flex items-center gap-2 no-underline hover:bg-red-500 h-12 px-2">
        <img src="../assets/crimsonreport.svg" class="invert w-8 h-8" />
        <div class="flex flex-col">
          <p class="font-mono leading-4">CRIMSON<span class="text-zinc-600">.REPORT</span></p>
          <small class="leading-none">Content owned on Destiny 2</small>
        </div>
      </a>
      <a href="https://loot.report" target="_blank" class="flex items-center gap-2 no-underline hover:bg-yellow-500 h-12 px-2">
        <img src="../assets/lootreport.svg" class="invert w-8 h-8" />
        <div class="flex flex-col">
          <p class="font-mono leading-4">LOOT<span class="text-zinc-600">.REPORT</span></p>
          <small class="leading-none">Exotics your clan owns on Destiny 2</small>
        </div>
      </a>
    </div>
  </div>
</template>
